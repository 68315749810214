.app-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .app-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .app-logo {
    width: 100px; 
    height: auto;
  }
  
  .app-title {
    margin-top: 10px;
  }
  
  .policy-content {
    line-height: 1.6;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  .policy-section {
    margin-bottom: 20px;
  }
  
  .policy-section h4 {
    margin-bottom: 10px;
  }
  
  .policy-section ul {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .policy-section li {
    margin-bottom: 5px;
  }
  
  .policy-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  .policy-section a:hover {
    text-decoration: underline;
  }
