@import url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/fonts/metropolis/style.css");

.landingpagemovementsbody {
  font-family: "Metropolis Regular";
  padding: 0px;
  margin: 0px;
}

.navbar-banner {
  background-color: #004dac;
}

.timer-desktop {
  position: absolute;
  z-index: 5;
  top: -4.5% !important;
  left: 75%;
  transform: translateX(-50%);
  width: 150%;
  background-color: #242424;
  color: #ffffff;
  padding: 0.3em;
  text-align: center;
}

.timer-desktop p {
  margin-bottom: 0em;
  font-size: 1.3em;
}

.timer-desktop span {
  font-size: 1.3em;
}

.heading-one {
  font-family: Arial, Helvetica, sans-serif;
  top: 4.5%;
  left: 9.5%;
  color: #004dac;
  font-size: 3em;
  font-weight: 900;
}

.heading-one-sub {
  color: #00ff7f;
}

.heading-two {
  font-family: Arial, Helvetica, sans-serif;
  top: 12.5%;
  left: 9.5%;
  color: #004dac;
  font-size: 1.4em;
  font-weight: 800;
}

.heading-three {
  font-family: Arial, Helvetica, sans-serif;
  top: 19.5%;
  left: 9.5%;
  font-weight: 800;
  color: #004dac;
  font-size: 1.2em;
}

.bold-logo {
  color: #004dac;
  font-weight: 800;
}

.navbar-banner-mainheading {
  font-family: "Metropolis Regular";
  font-size: 1.6em;
  text-transform: uppercase;
  color: #ffffff;
}

.form-container-main-banner {
  background-color: #ffffff;
  /* border-radius: 10px; */
  width: 150%;
}
.navbar-submainheading {
  font-weight: bolder;
}

.navbar-banner-heading {
  /* font-family: "Passion One", cursive; */
  font-size: 1em;
  text-transform: uppercase;
  color: #ffffff;
}

.navbar-banner-subheading {
  font-family: "Passion One", cursive;
  font-size: 1.7em;
  text-transform: uppercase;
  color: #00ff7f;
}

.map-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .map-view {
  width: 70%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* #map-marathon {
  height: 40vh;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
} */

.form-heading {
  color: #004dac;
  font-weight: 800;
  text-align: center;
}

.form-sub-container {
  background-color: #004dac;
}

.movements-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-container-movements {
  position: absolute;
  top: 15%;
  right: 17%;
  z-index: 2;
}

.movements-main-banner-form-input {
  font-size: 0.8;
  background-color: #f9f9fa;
  border: solid 1px #e0e0e0;
  color: black;
  width: 90% !important;
  margin-bottom: 1em;
  padding: 0.8em;
  box-sizing: border-box;
}

.movements-main-banner-form-input::placeholder {
  color: black;
}

.movements-main-banner-form-input:focus {
  outline: none;
}

.movements-form-input {
  background-color: transparent;
  border: solid 1px #ffffff;
  color: #ffffff;
  width: 30% !important;
  margin-bottom: 1em;
  font-size: 0.8em;
  padding: 1.5em;
  box-sizing: border-box;
}

.movements-form-input::placeholder {
  color: #ffffff;
}

.movements-form-input:focus {
  outline: none;
}

.movements-register-button {
  font-size: 0.9em;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0;
  color: #004dac;
  font-weight: 600;
}

.movements-register-button-main-banner {
  background-color: black;
  border: 1px solid black;
  border-radius: 0;
  color: #ffffff;
  font-size: 1em;
  /* font-weight: 600; */
}

.movements-tips-preps {
  background-color: black;
  border: none;
  padding: 0.5rem;
  color: #ffffff;
  font-size: 0.9em;
  text-decoration: none;
  /* font-weight: 600; */
}
.movements-tips-preps:hover {
  background-color: #ffffff;
  border: none;
  padding: 0.5rem;
  color: black;
  font-size: 0.9em;
  text-decoration: none;
  /* font-weight: 600; */
}

.movements-register-button-main-banner :hover {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  border-radius: 0;
  color: #ffffff;
  font-weight: 600;
}

.movements-register-button:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: black;
  font-weight: 800;
}

.movements-form-select {
  color: #004dac;
}

.faq-container {
  padding: 1.8em;
}

.accordion-button {
  color: #004dac;
  font-weight: 400;
}

.timer-passion-movements {
  font-family: "Passion One", cursive;
  font-size: 2.5em;
  text-transform: uppercase;
  color: #00ff7f;
}

.timer-position-movement {
  border: 2px solid black;
  top: -30%;
  left: 80%;
  right: 0;
  bottom: 55%;
  z-index: 40;
  text-align: center;
  transform: translateX(-50%);
  background-color: #ffffff;
  width: 30%;
}

.icon-image-event {
  width: 20%;
}

.icon-image-event-2 {
  width: 15%;
}

.icon-image-event-3 {
  width: 20%;
}

.banner-event-image {
  border-radius: 10px;
  border: 2px solid black;
  width: 30%;
  display: block;
}

.banner-event-image-2 {
  border-radius: 10px;
  border: 2px solid black;
  width: 80%;
  display: block;
}

.banner-event-image-3 {
  border-radius: 10px;
  border: 2px solid black;
  width: 40%;
  display: block;
}

.blue-heading {
  font-size: 1.8rem;
  color: #004dac;
  font-weight: 800;
}

.blue-heading-cta {
  font-size: 1.5rem;
  color: #004dac;
  font-weight: 800;
}

.blue-heading-details {
  font-size: 1.4rem;
  color: #004dac;
  font-weight: 800;
}

.p-tags {
  font-size: 1rem;
  text-align: left;
}

.black-small-heading {
  font-size: 1.5rem;
}

.small-icons {
  width: 35%;
}

#map-marathon {
  height: 60vh;
  width: 70%;
}

.journey-div {
  padding-right: 10em;
}

.distance-div {
  background-color: #242424;
  color: #ffffff;
  padding: 0.2em;
  text-align: center;
  width: 70%;
}

.distance-div p {
  margin-bottom: 0;
  font-size: 1.5em;
}

.prize-image-2 {
  width: 70%;
}

.tips-preps {
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .timer-desktop {
    top: -3.5% !important;
    left: 60%;
    width: 120%;
  }
  .btn-container-movements {
    position: absolute;
    top: 10%;
    right: 14%;
    z-index: 2;
  }

  .form-container-main-banner {
    background-color: #ffffff;
    width: 120%;
  }
  .prize-image-2 {
    width: 100%;
  }
}

@media screen and (max-width: 770px) {
  .icon-image-event-3 {
    width: 30px;
  }
  .heading-one {
    top: 4.5%;
    left: 4.5%;
    font-size: 1.55em;
  }

  .heading-two {
    top: 11%;
    left: 4.5%;
    font-size: 1em;
  }

  .heading-three {
    font-family: Arial, Helvetica, sans-serif;
    top: 20%;
    left: 4.5%;
    color: #004dac;
    font-size: 1em;
  }

  .navbar-banner-mainheading {
    font-family: "Metropolis Regular";
    font-size: 1.2em;
    text-transform: uppercase;
    color: #ffffff;
  }
  .navbar-banner-heading {
    /* font-family: "Passion One", cursive; */
    font-size: 0.9em;
    text-transform: uppercase;
    color: #ffffff;
  }

  .navbar-banner-subheading {
    font-family: "Passion One", cursive;
    font-size: 1.17em;
    text-transform: uppercase;
    color: #00ff7f;
  }

  .journey-div-sub-desktop {
    display: none !important;
  }
  .youtube-scroll .splide {
    padding-left: 0 !important;
  }

  .countdown-div {
    display: none;
  }

  .floating-timer {
    position: fixed;
    z-index: 5;
    bottom: 6% !important;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #242424;
    color: #ffffff;
    padding: 0.3em;
    text-align: center;
  }

  .floating-timer p {
    margin-bottom: 0.5em;
    font-size: 1em;
  }

  .floating-timer span {
    font-size: 1.3em;
  }

  .floating-cta-event {
    position: fixed;
    z-index: 5;
    bottom: 0% !important;
    left: 50%;
    text-align: center !important;
    transform: translateX(-50%);
    font-size: 1em;
    outline: 0px;
    border: 0px;
    padding: 0.8em;
    font-size: 2.2vmax;
    font-weight: 800;
  }

  .distance-div {
    width: 100%;
  }

  .distance-div p {
    font-size: 1em;
  }

  .floating-cta-event:hover {
    color: #f0f1f5;
  }

  .desktop-event-navbar {
    display: none;
  }

  .timer-position-movements {
    top: 2%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translateX(-50%);
  }

  .timer-passion-movements {
    font-family: "Passion One", cursive;
    font-size: 1.6em;
    text-transform: uppercase;
    color: #00ff7f;
  }

  .black-small-heading {
    font-size: 1rem;
  }
  .blue-heading {
    font-size: 1.2rem;
    color: #004dac;
    font-weight: 800;
  }

  .blue-heading-cta {
    font-size: 1.1rem;
    color: #004dac;
    font-weight: 800;
  }

  .journey-div {
    padding-right: 2em;
  }

  .small-icons {
    width: 140%;
  }

  .blue-heading-details {
    font-size: 1rem;
    color: #004dac;
    font-weight: 800;
  }

  .prize-image {
    width: 100%;
  }

  .banner-event-image {
    border-radius: 10px;
    border: 2px solid black;
    width: 100%;
    display: block;
  }

  .banner-event-image-3 {
    border-radius: 10px;
    border: 2px solid black;
    width: 100%;
    display: block;
  }

  .icon-image-event {
    width: 50%;
  }

  .icon-image-event-2 {
    width: 60%;
  }

  .icon-image-event-2 {
    width: 35%;
  }

  #map-marathon {
    height: 40vh;
    width: 100%;
  }

  /* .map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .blue-headings {
    color: #004dac;
    font-weight: 600;
  }

  .sub-blue-headings {
    color: #004dac;
    font-weight: 600;
    font-size: 0.8em;
  }

  #map-marathon {
    height: 40vh;
    width: 80%;
  } */
  .btn-container-movements {
    display: none;
  }

  /* .navbar-banner {
    display: none;
  } */

  .movements-form-input {
    width: 90% !important;
    padding: 1em;
  }

  .form-heading {
    font-size: 1.5em;
  }

  .btn-container-movements {
    position: absolute;
    top: 83%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}
