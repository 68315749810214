.x1-spec .bike-spec-tooltip-item img {
  opacity: 0;
  z-index: -1;
}
.bike-spec-tooltip {
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 15px white;
  background-color: #52ff00;
  z-index: 2;
}
.bike-spec-tooltip:hover {
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 15px white;
  background-color: #004dac;
}
.bike-spec-tooltip-item {
  z-index: 9;
}
.bike-spec-tooltip:hover ~ .bike-spec-tooltip-item {
  z-index: 9;
}

.x1-spec .bike-spec-tooltip:hover ~ .bike-spec-tooltip-item img {
  opacity: 1;
}

.x1-spec-1 {
  top: 40%;
  left: 49%;
}
.x1-spec-2 {
  top: 33%;
  left: 68%;
}
.x1-spec-3 {
  top: 57%;
  left: 74%;
}
.x1-spec-4 {
  top: 58%;
  left: 24%;
}
.x1-spec-5 {
  top: 2%;
  left: 65%;
}

.x1-limited-spec-5 {
  top: -4%;
  left: 35%;
}

.x1-spec-6 {
  top: 23%;
  left: 50%;
}

.x3-spec-1 {
  top: 40%;
  left: 45%;
}
.x3-spec-2 {
  top: 40%;
  left: 32.8%;
}
.x3-spec-3 {
  top: 61%;
  left: 29%;
}
.x3-spec-4 {
  top: 60%;
  left: 70%;
}
.x3-spec-5 {
  top: 2%;
  left: 39%;
}
.x3-spec-6 {
  top: 20%;
  left: 45%;
}

.emx-spec-1 {
  top: 30%;
  left: 45%;
}
.emx-spec-2 {
  top: 40%;
  left: 32.8%;
}
.emx-spec-3 {
  top: 61%;
  left: 29%;
}
.emx-spec-4 {
  top: 60%;
  left: 70%;
}
.emx-spec-5 {
  top: 53%;
  left: 70%;
}
.emx-spec-6 {
  top: 0%;
  left: 35%;
}
.emx-spec-7 {
  top: 17%;
  left: 45%;
}
.emx-spec-8 {
  top: 45%;
  left: 51%;
}
.neo-spec-1 {
  top: 38%;
  left: 47.5%;
}
.t-rex-spec-1 {
  top: 39%;
  left: 49.5%;
}
.t-rex-spec-2 {
  top: 29%;
  left: 64%;
}
.t-rex-spec-3 {
  top: 57%;
  left: 70%;
}
.neo-spec-3 {
  top: 53%;
  left: 70%;
}
.neo-spec-4 {
  top: 52%;
  left: 23%;
}
.t-rex-spec-4 {
  top: 56%;
  left: 29%;
}
.neo-spec-5 {
  top: 6%;
  left: 61%;
}
.t-rex-spec-5 {
  top: -4%;
  left: 61%;
}
.neo-spec-6 {
  top: 25.5%;
  left: 43%;
}
.t-rex-spec-6 {
  top: 20%;
  left: 52%;
}

.doodle-spec-1 {
  top: 40%;
  left: 48%;
}
.doodle-spec-2 {
  top: 45%;
  left: 34%;
}
.doodlev3-spec-2 {
  top: 45%;
  left: 35%;
}
.doodle-spec-3 {
  top: 68%;
  left: 30%;
}
.doodlev3-spec-3 {
  top: 65%;
  left: 31.5%;
}
.doodle-spec-4 {
  top: 68%;
  left: 67%;
}
.doodlev3-spec-4 {
  top: 64.5%;
  left: 65%;
}
.doodle-spec-5 {
  top: 61%;
  left: 67%;
}
.doodle-spec-6 {
  top: 33%;
  left: 42%;
}
.doodle-spec-7 {
  top: 0%;
  left: 40%;
}
.doodlev3-spec-7 {
  top: -3%;
  left: 40%;
}

.lile-spec-1 {
  top: 84%;
  left: 47.5%;
}
.lile-spec-2 {
  top: 82%;
  left: 37%;
}
.lile-spec-3 {
  top: 84%;
  left: 67%;
}
.lile-spec-4 {
  top: 78%;
  left: 67%;
}
.lile-spec-5 {
  top: 33%;
  left: 41%;
}
.lile-spec-6 {
  top: -4.4%;
  left: 43%;
}

.deagle-spec-1 {
  top: 40%;
  left: 46%;
}
.deagle-spec-2 {
  top: 40%;
  left: 33%;
}
.deagle-spec-3 {
  top: 60%;
  left: 28%;
}
.deagle-spec-4 {
  top: 60%;
  left: 52%;
}
.deagle-spec-5 {
  top: 53%;
  left: 70%;
}
.deagle-spec-6 {
  top: 19%;
  left: 45%;
}
.deagle-spec-7 {
  top: 40%;
  left: 53%;
}

@media only screen and (max-width: 1600px) {
  .x1-spec .bike-spec-tooltip-item img {
    opacity: 0;
    z-index: 2;
  }
  .bike-spec-tooltip {
    cursor: pointer;
    width: 17px;
    height: 17px;
    box-shadow: 0px 0px 16px white;
    background-color: #52ff00;
    z-index: 9;
  }
  .bike-spec-tooltip:hover {
    cursor: pointer;
    width: 17px;
    height: 17px;
    box-shadow: 0px 0px 16px white;
    background-color: #004dac;
  }
  .bike-spec-tooltip-item {
    z-index: 9;
  }
  .bike-spec-tooltip:hover ~ .bike-spec-tooltip-item {
    z-index: 9;
  }

  .x1-spec .bike-spec-tooltip:hover ~ .bike-spec-tooltip-item img {
    opacity: 1;
  }

  .x1-spec-1 {
    top: 39%;
    left: 48.5%;
  }

  .x1-spec-2 {
    top: 30%;
    left: 68%;
  }
  /* .neo-spec-3 {
    top: 58%;
    left: 79%;
  } */
  .x1-spec-3 {
    top: 57%;
    left: 73%;
  }
  .x1-spec-4 {
    top: 53%;
    left: 24%;
  }
  .neo-spec-4 {
    top: 57%;
    left: 16%;
  }
  .x1-spec-5 {
    top: 1%;
    left: 64%;
  }
  .x1-limited-spec-5 {
    top: -4%;
    left: 32%;
  }
  .x1-spec-6 {
    top: 18%;
    left: 52%;
  }

  .x3-spec-1 {
    top: 38%;
    left: 43%;
  }
  .x3-spec-2 {
    top: 36%;
    left: 28%;
  }
  .x3-spec-3 {
    top: 62%;
    left: 22.5%;
  }
  .x3-spec-4 {
    top: 62%;
    left: 76.5%;
  }
  .x3-spec-5 {
    top: 2%;
    left: 34%;
  }
  .x3-spec-6 {
    top: 23%;
    left: 45%;
  }

  .emx-spec-1 {
    top: 31%;
    left: 44%;
  }
  .emx-spec-2 {
    top: 38%;
    left: 27.5%;
  }
  .emx-spec-3 {
    top: 61%;
    left: 22%;
  }
  .emx-spec-4 {
    top: 61%;
    left: 76%;
  }
  .emx-spec-5 {
    top: 54%;
    left: 76%;
  }
  .emx-spec-6 {
    top: -3%;
    left: 32%;
  }
  .emx-spec-7 {
    top: 54%;
    left: 76%;
  }
  .emx-spec-8 {
    top: 45%;
    left: 51.7%;
  }
  .neo-spec-1 {
    top: 35%;
    left: 46.5%;
  }

  .t-rex-spec-1 {
    top: 38%;
    left: 48.5%;
  }
  .t-rex-spec-2 {
    top: 26%;
    left: 64%;
  }
  .neo-spec-3 {
    top: 48%;
    left: 68%;
  }
  .t-rex-spec-3 {
    top: 54%;
    left: 69.5%;
  }
  .neo-spec-5 {
    top: 2%;
    left: 61%;
  }
  .neo-spec-4 {
    top: 47%;
    left: 23%;
  }
  .t-rex-spec-4 {
    top: 52%;
    left: 29%;
  }
  .t-rex-spec-5 {
    top: -10%;
    left: 60%;
  }
  .neo-spec-6 {
    top: 21%;
    left: 43%;
  }
  .t-rex-spec-6 {
    top: 14%;
    left: 53%;
  }

  .doodle-spec-1 {
    top: 40%;
    left: 47%;
  }
  .doodle-spec-2 {
    top: 44%;
    left: 29.5%;
  }
  .doodlev3-spec-2 {
    top: 45%;
    left: 29.7%;
  }
  .doodle-spec-3 {
    top: 69%;
    left: 24%;
  }
  .doodlev3-spec-3 {
    top: 65%;
    left: 24%;
  }
  .doodle-spec-4 {
    top: 68%;
    left: 72%;
  }
  .doodlev3-spec-4 {
    top: 64.5%;
    left: 71.5%;
  }
  .doodle-spec-5 {
    top: 62%;
    left: 73%;
  }
  .doodle-spec-7 {
    top: -2%;
    left: 37%;
  }

  .doodlev3-spec-7 {
    top: -4%;
    left: 37%;
  }
  .doodle-spec-6 {
    top: 30%;
    left: 39%;
  }

  .lile-spec-1 {
    top: 84%;
    left: 47%;
  }
  .lile-spec-2 {
    top: 83%;
    left: 32%;
  }
  .lile-spec-3 {
    top: 84%;
    left: 73%;
  }
  .lile-spec-4 {
    top: 79%;
    left: 74%;
  }
  .lile-spec-5 {
    top: 33%;
    left: 38%;
  }
  .lile-spec-6 {
    top: -4%;
    left: 42%;
  }

  .deagle-spec-1 {
    top: 39%;
    left: 45%;
  }
  .deagle-spec-2 {
    top: 40;
  }
}

@media only screen and (max-width: 700px) {
  .x1-spec .bike-spec-tooltip-item img {
    opacity: 0;
    z-index: 2;
  }
  .bike-spec-tooltip {
    cursor: pointer;
    width: 10px;
    height: 10px;
    box-shadow: 0px 0px 8px white;
    background-color: #52ff00;
    z-index: 9;
  }
  .bike-spec-tooltip:hover {
    cursor: pointer;
    width: 10px;
    height: 10px;
    box-shadow: 0px 0px 12px white;
    background-color: #004dac;
  }
  .bike-spec-tooltip-item {
    z-index: 9;
  }
  .bike-spec-tooltip:hover ~ .bike-spec-tooltip-item {
    z-index: 9;
  }

  .x1-spec .bike-spec-tooltip:hover ~ .bike-spec-tooltip-item img {
    opacity: 1;
  }

  .x1-spec-1 {
    top: 47%;
    left: 48%;
  }
  .x1-spec-2 {
    top: 40%;
    left: 68%;
  }
  .x1-spec-3 {
    top: 64%;
    left: 72%;
  }
  .x1-spec-4 {
    top: 65%;
    left: 22%;
  }
  .x1-spec-5 {
    top: 8%;
    left: 63%;
  }

  .x1-limited-spec-5 {
    top: 4%;
    left: 30%;
  }

  .x1-spec-6 {
    top: 29%;
    left: 49%;
  }

  .x3-spec-1 {
    top: 49%;
    left: 45%;
  }
  .x3-spec-2 {
    top: 40%;
    left: 25%;
  }
  .x3-spec-3 {
    top: 67%;
    left: 18%;
  }
  .x3-spec-4 {
    top: 65%;
    left: 80%;
  }
  .x3-spec-5 {
    top: 10%;
    left: 33%;
  }
  .x3-spec-6 {
    top: 29%;
    left: 45%;
  }

  .emx-spec-1 {
    top: 38%;
    left: 43%;
  }
  .emx-spec-2 {
    top: 40%;
    left: 25%;
  }
  .emx-spec-3 {
    top: 67%;
    left: 18%;
  }
  .emx-spec-4 {
    top: 65%;
    left: 80%;
  }
  .emx-spec-5 {
    top: 3%;
    left: 25%;
  }
  .emx-spec-6 {
    top: 20%;
    left: 40%;
  }

  .t-rex-spec-1 {
    top: 45%;
    left: 50%;
  }
  .t-rex-spec-2 {
    top: 34%;
    left: 65%;
  }
  .t-rex-spec-3 {
    top: 65%;
    left: 74%;
  }
  .t-rex-spec-4 {
    top: 65%;
    left: 21%;
  }

  .air-spec-5 {
    top: 40%;
    left: 80%;
  }

  .t-rex-spec-5 {
    top: 6%;
    left: 62%;
  }
  .neo-spec-5 {
    top: 12.5%;
    left: 63%;
  }
  .t-rex-spec-6 {
    top: 29%;
    left: 51%;
  }

  .doodle-spec-1 {
    top: 45%;
    left: 46%;
  }
  .doodle-spec-2 {
    top: 50%;
    left: 25%;
  }
  .doodlev3-spec-2 {
    top: 50%;
    left: 27%;
  }
  .doodle-spec-3 {
    top: 72%;
    left: 20%;
  }
  .doodlev3-spec-3 {
    top: 70%;
    left: 22%;
  }
  .doodle-spec-4 {
    top: 78%;
    left: 75%;
  }
  .doodlev3-spec-4 {
    top: 70%;
    left: 72%;
  }
  .doodle-spec-5 {
    top: 67%;
    left: 75%;
  }
  .doodlev3-spec-5 {
    top: 6%;
    left: 36%;
  }

  .doodle-spec-6 {
    top: 2%;
    left: 35%;
  }
  .doodlev3-spec-6 {
    top: 40%;
    left: 38%;
  }

  .lile-spec-1 {
    top: 89%;
    left: 48%;
  }
  .lile-spec-2 {
    top: 40%;
    left: 35%;
  }
  .lile-spec-3 {
    top: 89%;
    left: 29%;
  }
  .lile-spec-4 {
    top: 89%;
    left: 76%;
  }

  .lile-spec-6 {
    top: 2%;
    left: 38%;
  }

  .deagle-spec-1 {
    top: 49%;
    left: 45%;
  }
  .deagle-spec-2 {
    top: 40%;
    left: 25%;
  }
  .deagle-spec-3 {
    top: 67%;
    left: 18%;
  }
  .deagle-spec-4 {
    top: 66.5%;
    left: 54%;
  }
  .deagle-spec-6 {
    top: 68%;
    left: 80%;
  }
  .deagle-spec-5 {
    top: 29%;
    left: 45%;
  }
}
