.footer p{
    margin: 0%;
    margin-bottom: 0.7em;
    color: rgb(192, 196, 199);
}
.footer p:hover{
    color: rgb(244, 246, 248);
    transition: ease-in;
    

} 



.footer h6{
    margin-bottom: 1.5em;
    font-weight: 600;
}

.socials i{
font-size: 1.5em;
color: rgb(228, 222, 222);
}