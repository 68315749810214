@media only screen and (max-width: 700px) {
  .spec-box p {
    font-size: 0.8em;
  }
  .mobile-bike-select {
    font-size: 0.8em;
  }
  .comparison-feature-block {
    height: 120px;
  }
  .comparison-specs {
    font-size: 0.7em;
  }
  .comparison-feature {
    font-size: 0.74em;
  }
  .bike-product-images {
    min-height: 43vh;
  }

  .bike-product-images img {
    width: 90%;
  }
  .slider-count {
    top: 3%;
    z-index: 3;
    right: 5%;
  }
  .slider-count h3 {
    font-size: 0.9em;
  }
  .structure-main-heading {
    font-size: 1.5rem;
  }

  .structure-sub-heading {
    font-size: 1.1rem;
  }

  .dimension-image {
    display: none;
  }
  .compare-features {
    font-size: 0.8rem;
  }
  .active-color {
    border: 2px solid #004dac !important;
  }

  .product-gif-specs {
    display: flex;
  }

  .product-gif-heading {
    position: absolute;
    top: 10%;
    left: 0%;
    z-index: 2;
    text-align: center;
  }

  .product-gif-heading h3 {
    font-weight: 900;
    font-size: 1rem;
    z-index: 5;
  }

  .product-gif-specs {
    width: 100%;
    position: absolute;
    top: 67%;
    right: 2%;
    z-index: 2;
  }

  .product-gif-specs h4 {
    font-size: 0.8em;
    font-weight: 900;
  }

  .product-gif-specs p {
    font-size: 0.7em;
    font-weight: 900;
  }
}
