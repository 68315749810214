.booking-div {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.booking-div {
  display: flex;
  flex-direction: row;
}

.left-section {
  width: 45%;
  border-right: 1px solid grey; /* Creates the dividing line */
}

.right-section {
  width: 65%;
  background-color: white; /* Adjust color as needed */
}

.factory-schedule-booking h1 {
  font-size: 1.5em;
}

.factory-schedule-booking h3 {
  font-size: 1.3em;
}

.factory-schedule-booking p {
  font-size: 0.9em;
}

.factory-schedule-booking .time-tab {
  color: #004dac;
  border: 2.5px solid #86bdff;
}

.factory-schedule-booking .time-tab:hover {
  color: #004dac;
  border: 2.5px solid #004dac;
}

@media (min-width: 768px) {
  .booking-div {
    width: 920px;
  }
}

@media (max-width: 768px) {
  .booking-div {
    flex-direction: column;
  }

  .left-section {
    width: 100%;
    border-right: none;
  }

  .right-section {
    width: 100%;
  }

  .factory-schedule-booking h1 {
    font-size: 1.3em;
  }

  .factory-schedule-booking h3 {
    font-size: 1.1em;
  }
}
