.faqs-formula-fun .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: white;
}

.faqs-formula-fun .accordion-body {
  /* color: #ff0000; */
}

.faqs-formula-fun .accordion-button {
  color: black;
  font-size: 0.65em;
}

.faqs-formula-fun .accordion-button::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22%3E%3Cpath fill%3D%22%23000000%22 d%3D%22M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z%22/%3E%3C/svg%3E");
}

.faqs-formula-fun .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22%3E%3Cpath fill%3D%22%23000000%22 d%3D%22M19 13H5v-2h14v2z%22/%3E%3C/svg%3E");
}

.faqs-formula-fun .accordion-item {
  color: black;
  border: none !important;
}

.faqs-formula-fun .accordion-button:focus {
  box-shadow: none;
}

.faqs-formula-fun {
  background-color: white;
}

.buy-formula-btn-hamleys {
  color: white;
  background-color: #ff0000;
}

.buy-formula-btn-hamleys:hover {
  color: #ff0000;
  background-color: white;
}

.buy-formula-btn-amazon:hover {
  color: white;
  background-color: black;
}

.formula-fun-red-line {
  width: 100%;
  height: 1px; /* Adjust the height to make the line thinner or thicker */
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #ff0000 35%,
    #ff0000 68%,
    #ffffff 100%
  );
}

.swiper-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  height: 8px;
  border-radius: 4px;
  position: relative;
}

.swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #ffce31; /* Progress color */
  height: 100%;
  border-radius: 4px;
}

.formula-fun-red-line-one {
  width: 1px;
  height: 180px;
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ff0000 35%,
    #ff0000 68%,
    #ffffff 100%
  );
}

.line-container {
  position: relative;
  width: 100%;
}

.formula-fun-black-left-line {
  width: 85%;
  height: 1px;
  background: linear-gradient(to right, #000000 0%, #ffffff 100%);
}

.images {
  position: absolute;
  bottom: 0;
  right: 15%;
  display: flex;
  align-items: end;
  gap: 10em;
}

.small-img {
  width: 50px;
  height: auto;
  margin-left: -15px;
}

.image-best-seller {
  width: 60%;
}

.formula-fun-black-right-line {
  width: 100%;
  height: 1px; /* Adjust the height to make the line thinner or thicker */
  background: linear-gradient(to left, #000000 0%, #ffffff 100%);
}

.heading-2-size {
  font-size: 1.3em;
}

.category-heading-size {
  font-size: 1.15em;
}

.usp-heading-size {
  font-size: 12px;
}

.category-flex-div {
  gap: 3em;
}

.usp-flex-div {
  gap: 1em;
}

.category-flex-inner-div {
  width: 180px;
}

.usp-flex-inner-div {
  width: 200px;
}

.inverted-commas-formula {
  font-size: 4em;
}

/* .choose-us-div img {
  width: 35%;
} */

.choose-us-div p {
  font-size: 0.9em;
}

.choose-us-div small {
  font-size: 0.85em;
}

.formula-fun-reviews-home {
  .swiper-container {
    display: flex;
    align-items: flex-end; /* Align all slides to the bottom */
    overflow: visible; /* Allow slides to extend outside the container */
  }

  .swiper-wrapper {
    overflow: visible; /* Ensure the slides are not cropped */
  }

  .swiper-slide {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align content at the bottom */
  }

  .swiper-slide-active {
    transform: scale(1.3); /* Enlarges the active slide */
    z-index: 10; /* Bring the active slide forward */
  }

  .swiper-slide img {
    width: 100%;
    height: auto; /* Ensure the image scales proportionally */
  }
}

.range-home-ff {
  gap: 1.5em;
  p {
    font-size: 1.2em;
  }
}

.range-parent-home-ff {
  /* background-color: #f8f8f8; */
}

.range-card-content-ff {
  box-shadow: 0px 5px 55px 20px rgba(0, 0, 0, 0.05);
}

.ff-black-line {
  height: 0.3px;
  background-color: black;
}

.review-div-ff {
  background-color: #efefef;
}

.review-content-ff {
  height: 155px;
}

.usp-flex-ff {
  gap: 1em;
  p {
    font-size: 0.8em;
  }
  small {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 600px) {
  .faqs-formula-fun .accordion {
    --bs-accordion-btn-icon-width: 0.9em;
    font-size: 0.9em;
  }
  .category-flex-div {
    gap: 1em;
  }
  .formula-fun-red-line-one {
    width: 18px;
    height: 100px;
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #ff0000 35%,
      #ff0000 68%,
      #ffffff 100%
    );
  }
  .category-flex-inner-div {
    width: 100%;
  }
  .category-heading-size {
    font-size: 0.65em;
  }
  .heading-2-size {
    font-size: 1.1em;
  }
  .heading-border-mobile {
    border-bottom: 2px solid black;
    width: fit-content;
  }
  .review-formula-fun {
    font-size: 0.8em;
  }
  .image-best-seller {
    width: 100%;
  }
  .best-seller-details-ff {
    flex-wrap: wrap;
    text-align: center;
  }
  .best-seller-color-selection-ff {
    /* justify-content: center; */
  }
  .choose-us-div p {
    white-space: wrap;
    text-overflow: ellipsis;
  }
  .choose-us-div p {
    font-size: 0.63em;
  }

  .choose-us-div small {
    font-size: 0.6em;
  }

  .range-parent-home-ff {
    background-color: #f8f8f8;
  }

  .range-home-ff {
    gap: 1em;
    flex-wrap: wrap;
    a {
      flex: 1 1 calc(50% - 16px);
    }
    p {
      font-size: 0.8em;
    }
  }

  .usp-flex-ff {
    gap: 1em;
    flex-wrap: wrap;
    .usp-div-ff {
      flex: 1 1 calc(50% - 16px);
    }
  }

  .range-card-content-ff {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border: none;
  }
  .usp-div-ff {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    background-color: white;
    img {
      width: 40%;
    }
  }
  .usp-parent-home-ff {
    background-color: #f8f8f8;
  }
  .faqs-formula-fun .accordion-button {
    font-size: 0.65em;
  }
  .review-section-ff {
    background-color: #f8f8f8;
  }

  .review-div-ff {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  .review-content-ff {
    height: 190px;
  }
  /* .faqs-formula-fun .accordion-button::after {
    padding-right: 25px;
  }

  .faqs-formula-fun .accordion-button:not(.collapsed)::after {
    padding-right: 25px;
  } */
}
