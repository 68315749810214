.popup-main {
	width: 50%;
	position: fixed;
	z-index: 3;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	box-shadow: 2px 2px 10px #c9c9c9;

	border-radius: 10px;
}
.popup-main h1 {
	font-size: 2em;
	font-weight: 600;
}
.popup-main p {
	font-size: 1em;
	color: rgb(34, 33, 33);
}
.popup-main input {
	padding: 1em;
}

.cancel-btn {
	position: absolute;
	top: 3%;
	right: 1%;
	z-index: 3;
	font-size: 1.5em;
}

@media only screen and (max-width: 700px) {
	.popup-main {
		width: 90%;
		position: fixed;
		z-index: 3;
		top: 50%;
		left: 50%;
		translate: -50% -50%;
		box-shadow: 2px 2px 10px #c9c9c9;

		border-radius: 10px;
	}
	.popup-main h1 {
		font-size: 1.5em;
		font-weight: 600;
		text-align: center;
	}
	.popup-main p {
		font-size: 0.8em;
		color: rgb(34, 33, 33);
		text-align: center;
	}
	.popup-main input {
		padding: 0.7em;
	}
	.cancel-btn {
		position: absolute;
		top: 2.5%;
		right: 3%;
		z-index: 3;
		font-size: 1.5em;
	}
}

.notify-me {
	width: 50vw;
	height: auto;
	z-index: 5;
	overflow: hidden;
	position: fixed;
	top: 50%;
	left: 50%;
	border-radius: 5px;
	translate: -50% -50%;
}
.notify-me h3 {
	line-height: 45px;
	font-weight: 900;
	font-size: 2em;
	color: #ffffff;
}
.notify-me h3 span {
	font-size: 1.5em;

	color: #10b068;
}

.notify-me p {
	font-size: 0.8em;
	color: #c7c5c5;
	letter-spacing: 2px;
	text-align: center;
}
.notify-me-close-btn {
	position: absolute;
	right: 1%;
	top: 1%;
	font-size: 1.8em;
	cursor: pointer;
	color: #c7c5c5;
}
.notify-me-content {
	width: 80%;
}

.notify-me input {
	width: 100%;
	display: block;
	border: none;
	padding: 0.5em;
	color: white;
	background: transparent;
	border-bottom: 1px solid rgb(61, 59, 59);
	outline: none;
}

@media only screen and (max-width: 1500px) {
	.notify-me {
		width: 60vw;
		height: auto;
		z-index: 5;
		overflow: hidden;
		position: fixed;
		top: 50%;
		left: 50%;
		border-radius: 5px;
		translate: -50% -50%;
	}
	.notify-me h3 {
		line-height: 45px;
		font-weight: 900;
		font-size: 1.5em;
		color: #ffffff;
	}
	.notify-me h3 span {
		font-size: 1.3em;

		color: #10b068;
	}

	.notify-me p {
		font-size: 0.8em;
		color: #c7c5c5;
		letter-spacing: 2px;
		text-align: center;
	}
	.notify-me-close-btn {
		position: absolute;
		right: 1%;
		top: 1%;
		font-size: 1.8em;
		cursor: pointer;
		color: #c7c5c5;
	}
	.notify-me-content {
		width: 90%;
	}

	.notify-me input {
		width: 100%;
		display: block;
		border: none;
		padding: 0.5em;
		color: white;
		background: transparent;
		border-bottom: 1px solid rgb(61, 59, 59);
		outline: none;
	}
}
@media only screen and (max-width: 700px) {
	.notify-me {
		width: 90%;
		height: auto;

		z-index: 5;
		overflow: hidden;
		position: fixed;
		top: 50%;
		left: 50%;
		border-radius: 5px;
		translate: -50% -50%;
	}
	.notify-me-close-btn {
		position: absolute;
		right: 1%;
		top: 0.5%;
		font-size: 1.5em;
		cursor: pointer;
		color: #b1aeae;
	}
	.notify-me-content {
		width: 100%;
	}
	.notify-me h3 {
		font-size: 1.3em;

		text-align: center;
		line-height: 40px;
	}
	.notify-me h3 span {
		font-size: 1.5em;
		display: block;
		text-align: center;
		color: #10b068;
	}
}
