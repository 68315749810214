@media screen and (min-width: 640px) {
  .p-font {
    font-size: 1.1em;
  }
  .text-main {
    font-size: 1.2em;
  }
  .gif-video {
    width: 700px !important;
  }
}

@media screen and (min-width: 1200px) {
  .kalki-banner-btn {
    top: 65%;
    left: 64%;
  }
}

@media screen and (min-width: 1500px) {
  .kalki-banner-btn {
    top: 65%;
    left: 67%;
  }
}
@media screen and (max-width: 640px) {
  .kalki-banner-btn {
    bottom: 7%;
    left: -80%;
  }
}

.kalki-btn {
  background-color: #004dac !important;
  color: #ffffff;
  border: white 2px solid;
  border-radius: 25px;
}

.fix-btn {
  background-color: white !important;
  color: #004dac;
  border-radius: 25px;
}

.kalki-btn:hover {
  background-color: #000000 !important;
  color: #ffffff;
}

.p-highlighted {
  font-size: 1.1em;
}

.spec-btn {
  border-radius: 25px;
  background-color: #004dac;
  color: #ffffff;
}

.spec-btn:hover {
  background-color: #ffffff;
  color: #004dac;
}
