.usp-card {
  background-color: #000000;
  box-shadow: 2px 4px 8px rgba(255, 255, 255, 0.2);
  border-radius: 15px;
}

.button-background {
  background: linear-gradient(to bottom, #1b0000 0%, #580007 100%);
  box-shadow: 0px 1px 8px rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  border: #580007 1px solid;
}

.button-background:hover {
  border: white 1px solid;
}

.t-rex-background {
  position: relative;
  width: 100%;
  background: linear-gradient(to top right, #000000 0%, #1d0204 100%);
}

.t-rex-background::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  background: linear-gradient(to bottom right, #000000 0%, #1d0204 100%);
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.usp .modal-content {
  border: none;
  background: linear-gradient(to top left, #000000 0%, #640101 100%);
  box-shadow: 4px 2px 8px rgba(255, 255, 255, 0.1);
}

@font-face {
  font-family: "Metropolis-light";
  src: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/Metroplolis/Metropolis-Light.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

.t-rex-form {
  font-family: "Metropolis-light";
  color: black;
}

.t-rex-form input {
  padding: 0;
  /* background: linear-gradient(to top left, #000000 0%, #640101 100%) !important; */
  border: none;
  box-shadow: 4px 2px 8px rgba(255, 255, 255, 0.1);
}

.t-rex-form .form-select {
  padding: 0.9em;
  /* background-color: #3b0404e0; */
  border: none;
  box-shadow: 4px 2px 8px rgba(255, 255, 255, 0.1);
  /* color: white !important; */
}
.red {
  background: linear-gradient(to top left, #000000 0%, #640101 100%) !important;
  background-color: #1b0000 !important;
  color: white !important;
  padding: 0.5em !important;
}
.t-rex-form .form-control {
  /* color: white !important; */
}

.desktop-form {
  top: 5%;
  right: 5%;
}

.button-pre-book h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

.button-pre-book {
  margin: 0.7em;
  transform: translateX(-50%);
  top: 58%;
  left: 73%;
}

@media screen and (max-width: 1400px) {
  .desktop-form h3 {
    font-size: 1.3em;
  }

  .desktop-form p {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 650px) {
  .heading {
    font-size: 1.15em;
  }
  .usp-card h4 {
    font-size: 1.2em;
  }
  .t-rex-form label {
    font-size: 0.7em;
  }

  .t-rex-form input {
    font-size: 0.9em;
  }

  .t-rex-form select {
    font-size: 0.7em;
  }

  .form-mobile h3 {
    font-size: 1.4em;
  }

  .form-mobile p {
    font-size: 0.9em;
  }

  .form-mobile {
    top: 30%;
  }
  .button-pre-book {
    transform: translateX(-50%);
    top: 20%;
    left: 50%;
    margin: 0;
  }
  .button-pre-book h1 {
    font-size: 1.7em;
    margin-bottom: 0.3em;
  }
  .button-pre-book a {
    font-size: 0.8em;
  }
}
