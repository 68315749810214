.app-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}
  .text-center {
    text-align: center;
  }
  
  .text-left {
    text-align: left;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  .unorderedList {
    list-style-type: none;
  }
  
  .unorderedList .listItem::before {
    content: '•';
    color: black;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
  

  .tnc-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  

  .tnc-section a:hover {
    text-decoration: underline;
  }