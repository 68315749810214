.spinwheel {
  position: absolute;
  top: 47%;
  left: 76%;
  transform: translate(-50%, -50%);
  place-items: center;
}

.congratulations-text {
  font-size: 6em;
}

.unlocked-text {
  font-size: 1.8em;
}

.reward-text {
  font-size: 4em;
}

.confetti-overlay {
  background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/Wheelbackground+copy+2-100.jpg");
}

.deal-wheel {
  --size: clamp(250px, 51.5vmin, 700px);
  --lg-hs: 0 3%;
  --lg-stop: 50%;
  --lg: linear-gradient(
    hsl(var(--lg-hs) 0%) 0 var(--lg-stop),
    hsl(var(--lg-hs) 20%) var(--lg-stop) 100%
  );

  position: relative;
  display: grid;
  grid-gap: calc(var(--size) / 20);
  align-items: center;
  grid-template-areas:
    "spinner"
    "trigger";
  font-family: "Girassol", sans-serif;
  font-size: calc(var(--size) / 21);
  line-height: 1;
}

.deal-wheel > * {
  grid-area: spinner;
}

.deal-wheel .btn-spin {
  grid-area: trigger;
  justify-self: center;
}

.spinner {
  position: relative;
  display: grid;
  border: 15px solid GOLD;
  align-items: center;
  grid-template-areas: "spinner";
  width: var(--size);
  height: var(--size);
  transform: rotate(calc(var(--rotate, 25) * 1deg));
  border-radius: 50%;
  box-shadow: inset 0 0 0 calc(var(--size) / 40) hsl(0deg 0% 0% / 0.06);
}

.spinner * {
  grid-area: spinner;
}

.prize {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 calc(var(--size) / 10) 0 calc(var(--size) / 50);
  font-size: 0.7em;
  width: 48%;
  transform-origin: center right;
  transform: rotate(var(--rotate));
  user-select: none;
}

.ticker {
  position: relative;
  left: calc(var(--size) / -10);
  width: calc(var(--size) / 4);
  height: calc(var(--size) / 20);
  background: var(--lg);
  z-index: 1;
  clip-path: polygon(20% 0, 100% 50%, 20% 100%, 0% 50%);
  transform-origin: center left;
}

.btn-spin {
  color: #004dac;
  background: #ffffff;
  border: none;
  font-family: inherit;
  line-height: inherit;
  padding: 0.9rem 2rem 1rem;
  border-radius: 14px;
  cursor: pointer;
  transition: opacity 200ms ease-out;
}

.btn-spin:focus {
  outline-offset: 2px;
}

.btn-spin:active {
  transform: translateY(1px);
}

.btn-spin:disabled {
  cursor: progress;
  opacity: 0.25;
}

/* Spinning animation */
.is-spinning .spinner {
  transition: transform 8s cubic-bezier(0.1, -0.01, 0, 1);
}

.is-spinning .ticker {
  animation: tick 700ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes tick {
  40% {
    transform: rotate(-12deg);
  }
}

/* Selected prize animation */
.prize .selected .text {
  color: white;
  animation: selected 800ms ease;
}

@keyframes selected {
  25% {
    transform: scale(1.25);
    text-shadow: 1vmin 1vmin 0 hsla(0 0% 0% / 0.1);
  }
  40% {
    transform: scale(0.92);
    text-shadow: 0 0 0 hsla(0 0% 0% / 0.2);
  }
  60% {
    transform: scale(1.02);
    text-shadow: 0.5vmin 0.5vmin 0 hsla(0 0% 0% / 0.1);
  }
  75% {
    transform: scale(0.98);
  }
  85% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1460px) {
  .spinwheel {
    top: 48%;
    left: 76.1%;
  }

  .deal-wheel {
    --size: clamp(250px, 40vmin, 700px);
  }
}

@media screen and (max-width: 1390px) {
  .spinwheel {
    top: 48%;
    left: 76.1%;
  }

  .deal-wheel {
    --size: clamp(250px, 44.5vmin, 700px);
  }
}

@media screen and (max-width: 640px) {
  .unlocked-text {
    font-size: 1em;
  }
  .reward-text {
    font-size: 2.5em;
  }
  .congratulations-text {
    font-size: 4em;
  }

  .deal-wheel {
    --size: clamp(250px, 77.5vmin, 700px);
  }

  .spinwheel {
    top: 36.7%;
    left: 51%;
  }

  .prize {
    padding: 0 calc(var(--size) / 10) 0 calc(var(--size) / 100);
    width: 43%;
    font-size: 0.8em;
  }

  .confetti-overlay {
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/iPhone+8+Plus+-+1.jpg");
  }

  .btn-spin {
    font-size: 1.3em;
  }
}
