/* large devices view */
.landingpage-form-div {
  width: 450px;
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1766px) {
  #landingform input {
    font-size: 0.9em;
  }
  #landingform select {
    font-size: 0.9em;
  }
  .landingpage-form-div-main button {
    font-size: 0.9em;
  }
  .landingpage-form-div {
    width: 375px;
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 1500px) {
  .call-icon {
    top: 67%;
    left: 45%;
    transform: translate(-50% -50%);
  }
  .whatsapp-icon {
    top: 67%;
    left: 53%;
    transform: translate(-50% -50%);
  }
  .landingpage-main-div {
    width: 100%;
    height: 900px;
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/1440x800.jpg");
    background-size: cover;
  }
  .landingpage-main-logo {
    width: 300px;
  }

  .starting-at {
    background-color: white;
    top: 75%;
    right: 0%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .landingpage-form-div-main {
    background-color: #fff;
    box-shadow: 2px 2px 8px rgb(199, 193, 193), -2px 2px 8px rgb(199, 193, 193);
    border-radius: 5px;
  }

  .landingpage-form-div-main .form-input {
    width: 100%;
    border-radius: 8px;
    outline: 0px;
    border: 0.2px solid #aaa1a1;
    padding: 8px;
    padding-left: 15px;
    background-color: #f4f4f4;
  }
  .landingpage-form-div-main select {
    width: 100%;
    border-radius: 8px;
    outline: 0px;
    border: 0.2px solid #aaa1a1;
    padding: 8px;
    padding-left: 15px;
    background-color: #f4f4f4;
  }
  .submit-btn-lg {
    color: #fff !important;
    background-color: #004dac !important;
    font-size: 1.1em;
    padding: 0.6em;
    font-weight: 600;
  }
  .landingpage-form-div-main small {
    font-size: 0.7em;
    display: block;
    text-align: center;
  }
  .landing-page-marketplaces img {
    width: 40%;
  }

  .riding-cost h2 {
    font-size: 3.5em;
  }
  .riding-cost p {
    color: #ababab;
  }

  .x-factor-landind-section {
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/1440x483b.jpg");

    background-size: 100%;
    background-repeat: no-repeat;
  }
  .x-factor-landind-section h1 {
    font-size: 4em;
    font-weight: 900;
    color: white;
    text-align: center;
    margin-top: 1em;
  }
  .landing-bike-card {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 25%), -2px 2px 8px rgba(0, 0, 0, 25%);
  }

  .desire-landind-section {
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/1440x483a.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .desire-landind-section h1 {
    font-size: 4em;
    font-weight: 900;
    color: white;
    text-align: center;
    margin-top: 1em;
  }
  .submit-btn-lg {
    width: 25%;
  }
}

/* medium devices view */
@media only screen and (max-width: 1500px) {
  .call-icon {
    top: 67%;
    left: 45%;
    transform: translate(-50% -50%);
  }
  .whatsapp-icon {
    top: 67%;
    left: 53%;
    transform: translate(-50% -50%);
  }
  .landingpage-main-div {
    width: 100%;
    height: 800px;
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/1440x800.jpg");
    background-size: cover;
  }
  .landingpage-main-logo {
    width: 300px;
  }

  .landingpage-form-div-main {
    background-color: #fff;
    box-shadow: 2px 2px 8px rgb(199, 193, 193), -2px 2px 8px rgb(199, 193, 193);
    border-radius: 5px;
  }
  .starting-at {
    background-color: white;
    top: 75%;
    right: 0%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .landingpage-form-div-main .form-input {
    width: 100%;
    border-radius: 8px;
    outline: 0px;
    border: 0.2px solid #aaa1a1;
    padding: 8px;
    padding-left: 15px;
    background-color: #f4f4f4;
  }

  .landingpage-form-div-main select {
    width: 100%;
    border-radius: 8px;
    outline: 0px;
    border: 0.2px solid #aaa1a1;
    padding: 8px;
    padding-left: 15px;
    background-color: #f4f4f4;
  }
  .submit-btn-lg {
    color: #fff !important;
    background-color: #004dac !important;
    font-size: 1.1em;
    padding: 0.6em;
    font-weight: 600;
  }
  .landingpage-form-div-main small {
    font-size: 0.7em;
    display: block;
    text-align: center;
  }
  .landing-page-marketplaces img {
    width: 40%;
  }
  .riding-cost h2 {
    font-size: 3em;
  }
  .riding-cost p {
    color: #ababab;
  }

  .x-factor-landind-section {
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/1440x483b.jpg");

    background-size: 100%;
    background-repeat: no-repeat;
  }
  .x-factor-landind-section h1 {
    font-size: 4em;
    font-weight: 900;
    color: white;
    text-align: center;
    margin-top: 1em;
  }
  .landing-bike-card {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 25%), -2px 2px 8px rgba(0, 0, 0, 25%);
  }

  .desire-landind-section {
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/1440x483a.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .desire-landind-section h1 {
    font-size: 4em;
    font-weight: 900;
    color: white;
    text-align: center;
    margin-top: 1em;
  }
  .submit-btn-lg {
    width: 25%;
  }
}

/* mobile view */
@media only screen and (max-width: 700px) {
  .call-icon {
    top: 67%;
    left: 40%;
    transform: translate(-50% -50%);
  }
  .whatsapp-icon {
    top: 67%;
    left: 53%;
    transform: translate(-50% -50%);
  }
  .landingpage-main-div {
    width: 100%;
    height: 200px;
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/Landing-Page-Banner-(Desktop)+(1).jpg");
    background-size: cover;
    background-position: right;
  }
  .landingpage-main-logo-sm {
    width: 150px;
    position: absolute;
    top: 12%;
    right: 5%;
  }

  .landingpage-form-div {
    width: 450px;
    position: absolute;
    top: 17%;
    right: 8%;
    translate: -10% -10%;
  }

  .landingpage-form-div-main {
    background-color: #fff;
    box-shadow: 2px 2px 8px rgb(199, 193, 193), -2px 2px 8px rgb(199, 193, 193);
    border-radius: 5px;
  }
  .starting-at {
    color: #ffffff;
    background-color: black;
    top: 45%;
    right: 79%;
    translate: 80% -50%;
  }
  .starting-at p {
    font-size: 0.8em;
    margin-bottom: 0.3em !important;
  }
  .starting-at h3 {
    font-size: 1.2em;
  }

  .landingpage-form-div-main .form-input {
    width: 100%;
    border-radius: 8px;
    outline: 0px;
    border: 0.2px solid #aaa1a1;
    padding: 8px;
    padding-left: 15px;
    font-size: 0.8em;

    background-color: #f4f4f4;
  }
  .landingpage-form-div-main select {
    width: 100%;
    border-radius: 8px;
    outline: 0px;
    border: 0.2px solid #aaa1a1;
    padding: 8px;

    padding-left: 15px;
    font-size: 0.8em;

    background-color: #f4f4f4;
  }
  .landingpage-form-div-main .form-input::placeholder {
    font-size: 0.9em;
  }

  .submit-btn-lg {
    color: #fff !important;
    background-color: #004dac !important;
    font-size: 1.1em;
    padding: 0.6em;
    font-weight: 600;
  }
  .landingpage-form-div-main small {
    font-size: 0.7em;
    display: block;
    text-align: center;
  }
  .landing-page-marketplaces img {
    width: 60%;
  }
  .landing-page-marketplaces-main h5 {
    font-size: 1em;
  }
  .landing-page-marketplaces p {
    font-size: 0.8em;
  }
  .riding-cost h2 {
    font-size: 1.5em;
  }
  .riding-cost h4 {
    font-size: 1.2em;
  }
  .riding-cost p {
    color: #ababab;
    font-size: 0.9em;
  }
  .landing-service h5 {
    font-size: 1em;
  }
  .landing-service img {
    width: 50%;
  }
  .landing-service p {
    font-size: 0.6em;
  }

  .x-factor-landind-section {
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/1440x483b.jpg");

    background-size: contain;
    background-repeat: no-repeat;
  }
  .x-factor-landind-section h1 {
    font-size: 2em;
    font-weight: 900;
    color: white;
    text-align: center;
    margin-top: 0em;
  }
  .Specifications h2 {
    font-size: 1.2em;
  }
  .Specifications img {
    width: 40%;
    display: block;
    margin: auto;
  }

  .Specifications h6 {
    font-size: 0.7em;
    text-align: center;
  }
  .Specifications p {
    text-align: center;
    font-size: 0.55em;
  }

  .landing-bike-card {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 25%), -2px 2px 8px rgba(0, 0, 0, 25%);
  }

  .desire-landind-section {
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/1440x483a.jpg");

    background-size: cover;
    background-repeat: no-repeat;
  }
  .desire-landind-section h1 {
    font-size: 2em;
    font-weight: 900;
    color: white;
    text-align: center;
    margin-top: 0em;
  }

  .landing-black-stripe {
    font-size: 1em;
  }
  .landing-contact h3 {
    font-size: 1.2em;
    line-height: 30px;
  }
  .landing-contact h4 {
    font-size: 1em;
    line-height: 30px;
  }
  .submit-btn-lg {
    width: auto;
  }
}
