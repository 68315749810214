.survey-screen {
  height: 100vh;
}

.survey-component {
  border-top: 3px solid #004dac;
  padding: 0.8em;
  margin: 0;
  display: flex;
  height: 92.5vh;
  justify-content: center;
  align-items: center; /* Make the body (viewport) height 100% */
  background-color: #f3f3f3; /* Example background color */
}

.survey-form h4 {
  font-size: 1.3em;
}

.logo-bar {
  height: 7.5vh;
}

.survey-form {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.survey-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.rating-input {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.rating-input label {
  position: relative;
  cursor: pointer;
}

.rating-input input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.rating-input span {
  font-size: 1.2em !important;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.rating-input input[type="radio"]:checked + span {
  color: #ffffff;
  background-color: #004dac;
}

.feedback-text {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin-top: 20px;
  max-height: 0;
  overflow: hidden;
}

.feedback-text.show {
  opacity: 1;
  max-height: 1000px; /* Adjust this value as needed */
}

.feedback-text p {
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.submit-button {
  background-color: #004dac;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .survey-component {
    height: 115vh;
  }
  .rating-input span {
    height: 45px;
    font-size: 1em !important;
    width: 45px;
  }
  .rating-input {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
  .survey-form {
    padding: 10px;
  }
  .survey-form {
    text-align: center;
  }
  .survey-form h4 {
    font-size: 1.2em;
  }
  .survey-component {
    /* height: 100vh; */
  }
  .logo-bar {
    height: fit-content;
  }
}
