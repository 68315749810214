.payment-form-modal .modal {
  z-index: 10500 !important;
}

.payment-form-modal .modal-content {
  width: 75%;
}

.payment-form-modal .modal-backdrop {
  z-index: 10 !important;
}

.payment-form-modal .modal-header {
  border: none;
  padding: 0;
}
.payment-form-modal .modal-body {
  padding: 0;
}

.payment-form-modal .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.8em auto no-repeat;
}

@media screen and (max-width: 650px) {
  .payment-form-modal .modal-content {
    width: 90%;
  }
  .payment-form-modal .form-control,
  .form-select {
    font-size: 0.85em;
  }
}
