.empty-cart {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-cart img {
  width: 70%;
  display: block;
  margin: auto;
}
.empty-cart div {
  translate: -0% -10%;
}
.empty-cart h1 {
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
}

.empty-cart p {
  font-size: 0.95em;
  margin-top: 1em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.3em;
}
.empty-cart a {
  width: 25%;

  margin: auto;
}

/* //////////// */

.field-wrapper {
  position: relative;
}

.field-wrapper input {
  border: 1px solid #b1b3b6;
  padding: 10px;
  padding-left: 15px;
  border-radius: 4px;
  background-color: transparent;
  outline: none !important;
  width: 100%;
}

.field-wrapper select {
  border: 1px solid #b1b3b6;
  padding: 10px;
  padding-left: 15px;
  border-radius: 4px;
  background-color: transparent;
  outline: none !important;
  width: 100%;
}

.field-wrapper input:focus {
  box-shadow: 1px 1px 8px #67835944;
}

.field-wrapper .field-placeholder {
  font-size: 16px;
  position: absolute;
  /* background: #fff; */
  bottom: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #595c5f;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  left: 8px;
  padding: 0 8px;
  z-index: -1;
  -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);

  text-align: left;
  width: 100%;
}

.field-wrapper .field-placeholder span {
  background: #ffffff;
  padding: 0px 8px;
}

.field-wrapper input:not([disabled]):focus ~ .field-placeholder {
  color: #6c6f74;
  z-index: 1;
}

.field-wrapper input:not([disabled]):focus ~ .field-placeholder,
.field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
  -webkit-transform: scale(0.75) translateY(-30px) translateX(-55px);
  transform: scale(0.75) translateY(-30px) translateX(-55px);
}

.bg-white {
  background-color: white;
}

.cart-item {
  box-shadow: 1px 1px 8px rgb(226, 223, 223);
  border-radius: 5px;
}

.cart-heading {
  font-size: 0.93em;
  font-weight: 600;
}

.order-summary {
  background-color: #effbff;
}

/* MultiStepForm.css */

.form__group {
  display: flex;
  align-items: center;
  gap: 1em;
}

.form__label {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  align-items: start;
  background-color: #f7f7f7;
  border-radius: 15px;
  border: 1px solid #f7f7f7;
  height: 50px;
  cursor: pointer;
  padding: 1em;
  position: relative;
  text-align: left;
  width: 100%;
  transition: all 0.2s ease;
}

.form__label .razorpay-logo {
  margin: auto;
  max-height: 100%; /* Ensure the images take up a reasonable portion of the label */
  object-fit: contain; /* Ensure images fit within their container without distortion */
}

.form__label .payu-logo {
  margin: auto;
  max-height: 66%; /* Ensure the images take up a reasonable portion of the label */
  object-fit: contain; /* Ensure images fit within their container without distortion */
}

.form__input {
  display: none;
}

.form__label-check {
  color: #006ec8;
  fill: #006ec8;
  stroke-opacity: 1;
  stroke: #f7f7f7;
  background: #f7f7f7;
  border-radius: 100%;
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 100%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.form__input:checked ~ .form__label-check {
  visibility: visible;
  display: block;
}

/* New styles for changing background color of form__label */
.form__input:checked + .form__label {
  background-color: #d1e7ff; /* Change this to your desired color */
  border: 1px solid #004dac;
}

@media only screen and (max-width: 700px) {
  .cart-heading {
    font-size: 0.8em;
    font-weight: 600;
  }
  .field-wrapper .field-placeholder {
    font-size: 14px;
    position: absolute;
    /* background: #fff; */
    bottom: 13px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #80868b;
    left: 1px;
    padding: 0 8px;
    -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;

    text-align: left;
    width: 100%;
  }

  .field-wrapper input:not([disabled]):focus ~ .field-placeholder,
  .field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
    -webkit-transform: scale(0.8) translateY(-30px) translateX(-40px);
    transform: scale(0.8) translateY(-30px) translateX(-40px);
  }
  .field-wrapper input:not([disabled]):focus ~ .field-placeholder {
    color: #6c6f74;
    z-index: 1;
  }
}
