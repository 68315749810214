.product-heading {
  font-size: 4em;
}

.spec-key-point-div {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 25%;
}

.fleet-heading-ff {
  gap: 1.5em;
}

.ff-category-selection-btn {
  background-color: #3d348b;
  color: white;
  cursor: pointer;
}

.ff-category:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #3d348b;
}

.h2-all-product-page-ff {
  font-size: 2.7em;
}

.prev-category-button,
.next-category-button {
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .product-heading {
    font-size: 2.7em;
  }

  .category-formula-fun {
    flex-direction: column;
    gap: 2em;
  }

  .spec-key-point-div {
    min-width: fit-content;
  }

  .toy-usp-section {
    overflow-x: scroll;
  }

  .fleet-heading-ff {
    flex-direction: column;
    text-align: center;
    gap: 0.5em;
  }

  .h2-all-product-page-ff {
    font-size: 1.7em;
  }

  .ff-category,
  .ff-category-selection-btn {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .all-product-mobile-div-ff {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
}
