.trust-text-ecycle-page {
  font-size: 1.7em;
}

.trust-section-ecycle-page {
  justify-content: space-around;
}

.trust-section-ecycle-page .amazon {
  width: 150px;
}

.trust-section-ecycle-page .flipkart {
  width: 175px;
}

.benefits-section-ecycle-page h1 {
  font-size: 2em;
}

.benefits-section-ecycle-page small {
  font-size: 1em;
}

.benefits-section-ecycle-page h4 {
  font-size: 1.5em;
}

.form-placement-ecycle-page h5 {
  color: white;
}

.form-placement-ecycle-page button:hover {
  border: none;
}

.form-placement-ecycle-page button {
  border: none;
}

.form-div-ecycle-page {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.form-div-ecycle-page input {
  font-size: 0.8em;
  border: none;
}

.modal-dialog-ecycle-page input {
  font-size: 0.8em;
}

.form-div-ecycle-page .form-select {
  font-size: 0.8em;
  border: none !important;
}

.brand-usp-ecycle-page h3 {
  font-size: 2.2em;
}

.testimonials-ecycle-page .image-div {
  z-index: -10;
  left: 40%;
  width: 20%;
  top: -47%;
}
.testimonials-ecycle-page .swiper-slide {
  padding-top: 85px;
}

.testimonials-ecycle-page .testimonial-content {
  height: 125px;
}

.form-placement-ecycle-page {
  right: 5%;
  top: 50%;
}

@media (max-width: 1500px) {
  .form-placement-ecycle-page {
    right: 5%;
    top: 47%;
  }
  .benefits-section-ecycle-page h1 {
    font-size: 1.8em;
  }
  .testimonials-ecycle-page .image-div {
    top: -30%;
    left: 38%;
  }
  .testimonials-ecycle-page .swiper-slide {
    padding-top: 65px;
  }
  .testimonials-ecycle-page .testimonial-content {
    height: 160px;
  }
}

@media (max-width: 1400px) {
  .testimonials-ecycle-page .image-div {
    z-index: -10;
    left: 37%;
    width: 22%;
    top: -28%;
  }
  .testimonials-ecycle-page .swiper-slide {
    padding-top: 65px;
  }
  .testimonials-ecycle-page .testimonial-content {
    height: 180px;
  }
}

@media (max-width: 1300px) {
  .testimonials-ecycle-page .image-div {
    z-index: -10;
    left: 37%;
    width: 22%;
    top: -25%;
  }
  .testimonials-ecycle-page .swiper-slide {
    padding-top: 65px;
  }
  .testimonials-ecycle-page .testimonial-content {
    height: 190px;
  }
}

@media (min-width: 600px) {
  .form-div-ecycle-page {
    width: 400px;
  }

  .benefits-section-ecycle-page h2 {
    font-size: 2em;
  }

  .benefits-section-ecycle-page h3 {
    font-size: 1.6em;
  }

  .modal-dialog-ecycle-page {
    width: 400px;
  }
  .store-name-size {
    font-size: 1.2em;
  }
  .store-address-size {
    font-size: 0.9em;
  }
  .store-name-height {
    height: 30px;
  }
  .store-address-height {
    height: 40px;
    line-height: 0.9em;
  }
}

@media (max-width: 600px) {
  .store-name-size {
    font-size: 1.1em;
  }
  .store-address-size {
    font-size: 0.8em;
  }
  .store-name-height {
    height: 40px;
  }
  .store-address-height {
    height: 60px;
    line-height: 0.9em;
  }
  .form-div-ecycle-page {
    background: rgba(255, 255, 255, 0.55);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  .form-placement-ecycle-page h5 {
    color: black;
    font-weight: bolder;
  }

  .form-placement-ecycle-page {
    top: 60%;
    right: 0;
    width: 100%;
  }
  .form-placement-ecycle-page .form-placement-inner-div {
    justify-content: center;
  }
  .trust-text-ecycle-page {
    font-size: 0.75em;
  }

  .trust-section-ecycle-page .amazon {
    width: 50px;
  }

  .trust-section-ecycle-page .flipkart {
    width: 65px;
  }

  .benefits-section-ecycle-page h1 {
    font-size: 20px;
  }
  .benefits-section-ecycle-page h4 {
    font-size: 0.9em;
  }
  .benefits-section-ecycle-page p {
    font-size: 0.9em;
  }
  .benefits-section-ecycle-page h5 {
    font-size: 18px;
  }

  .brand-usp-ecycle-page h3 {
    font-size: 20px;
  }

  .testimonials-ecycle-page .image-div {
    left: 37%;
    width: 25%;
    top: -25%;
  }
  .testimonials-ecycle-page .swiper-slide {
    padding-top: 65px;
  }
  .testimonials-ecycle-page .testimonial-content {
    height: 200px;
  }
}
