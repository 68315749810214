.warranty-border-heading {
  font-size: 4em;
  font-weight: 900;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #353b48;
  margin: 0px;
  line-height: 35px;
  color: transparent;
  margin-top: 1em;
}

.background-warranty {
  background-color: #004dac;
}

.form-content {
  gap: 2.5em;
}

.warranty-grid td {
  padding: 7px;
  border: 1px solid black;
}

.warranty-form input {
  background-color: transparent;
  padding: 0.5em;
  font-size: 1.5em;
  border: 0px;
  margin-bottom: 1.95em;
  font-weight: 600;
  padding-bottom: 0.2em;
  color: #232427;
  outline: 0px;
  width: 90%;
  border-bottom: 1px solid rgb(170, 168, 168);
}

.warranty-form select {
  background-color: transparent;
  padding: 0.5em;
  font-size: 1.5em;
  border: 0px;
  margin-bottom: 1.95em;
  font-weight: 600;
  padding-bottom: 0.2em;
  color: #232427;
  outline: 0px;
  width: 90%;
  border-bottom: 1px solid rgb(170, 168, 168);
}

.invoice-label {
  background-color: transparent;
  padding: 0.5em;
  font-size: 1.5em;
  border: 0px;
  margin-bottom: 1.95em;
  font-weight: 600;
  padding-bottom: 0.2em;
  color: #232427;
  outline: 0px;
  width: 90%;
  border-bottom: 1px solid rgb(170, 168, 168);
}

.warranty-form option:active {
  background-color: grey;
  font-weight: 900;
  border: 0px;
}

.warranty-form input::placeholder {
  letter-spacing: 3px;
}

.form-content-inner-div {
  width: 50%;
}

.warranty-btn {
  width: 20%;
}
.warranty-grid {
  font-size: 1em;
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
}
.warranty-container {
  font-size: 0.9em;
}

lottie-player {
  position: absolute;
  top: 50%;
  width: 250px;
  transform: translateY(-50%);
  animation-name: move;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes move {
  0% {
    left: -10vw;
  }

  25% {
    left: 25vw;
  }
  50% {
    left: 50vw;
  }

  75% {
    left: 75vw;
  }

  100% {
    left: 100vw;
  }
}

@media screen and (max-width: 600px) {
  lottie-player {
    width: 150px;
  }
  .background-warranty {
    background-color: #004dac;
  }

  .form-content-inner-div {
    width: 100%;
  }
  .warranty-btn {
    width: 60%;
  }
  .form-content {
    gap: 0em;
    flex-direction: column;
  }
  .policy-heading {
    font-size: 1.18em;
  }

  .warranty-border-heading {
    font-size: 2.5em;
    font-weight: 900;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #353b48;
    margin: 0px;
    line-height: 50px;
    color: transparent;
    margin-top: 1em;
  }
  .warranty-border-subheading {
    font-size: 0.9em;
    font-weight: 700;
    margin-top: 0.5em;
  }
  .warranty-form input {
    background-color: transparent;
    padding: 0.5em;
    font-size: 1.5em;
    border: 0px;
    margin-bottom: 2em;
    font-weight: 600;
    padding-bottom: 0.2em;
    color: #232427;
    outline: 0px;
    width: 100%;
    border-bottom: 1px solid rgb(170, 168, 168);
  }

  .warranty-form select {
    background-color: transparent;
    padding: 0.5em;
    font-size: 1em;
    border: 0px;
    margin-bottom: 1.5em;
    font-weight: 600;
    padding-bottom: 0em;
    color: #232427;
    outline: 0px;
    width: 90%;
    border-bottom: 1px solid rgb(170, 168, 168);
  }

  .invoice-label {
    background-color: transparent;
    padding: 0.5em;
    font-size: 1em;
    border: 0px;
    margin-bottom: 1.95em;
    font-weight: 600;
    padding-bottom: 0.2em;
    color: #232427;
    outline: 0px;
    width: 100%;
    border-bottom: 1px solid rgb(170, 168, 168);
  }

  .warranty-form option:active {
    background-color: grey;
    font-weight: 900;
    border: 0px;
  }

  .warranty-form input::placeholder {
    letter-spacing: 3px;
  }

  .warranty-form input {
    background-color: transparent;
    padding: 0.5em;
    font-size: 1em;
    border: 0px;
    margin-bottom: 1.5em;
    font-weight: 600;
    padding-bottom: 0.2em;
    color: #232427;
    outline: 0px;
    width: 100%;
    border-bottom: 1px solid rgb(170, 168, 168);
  }

  .warranty-form select {
    background-color: transparent;
    padding: 0.5em;
    font-size: 1em;
    border: 0px;
    margin-bottom: 1.5em;
    font-weight: 600;
    padding-bottom: 0em;
    color: #232427;
    outline: 0px;
    width: 100%;
    border-bottom: 1px solid rgb(170, 168, 168);
  }

  .warranty-form option:active {
    background-color: grey;
    font-weight: 900;
    border: 0px;
  }

  .warranty-form input::placeholder {
    letter-spacing: 3px;
  }
  .warranty-grid {
    font-size: 0.9em;
  }
  .warranty-container {
    font-size: 0.8em;
  }
}
