.breadcrumb-container {
  background-color: white; /* Set a background to prevent overlapping */
  border-bottom: 1px solid #ddd;
}

.breadcrumb {
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.breadcrumb-item a {
  color: #004dac;
  font-family: "Metropolis-medium";
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 650px) {
  .breadcrumb-container {
    top: 8.4%;
  }
}
