.track-order h1 {
	font-size: 2.2em;
	font-weight: 700;
}

.track-order button {
	font-size: 0.9em;
	width: 30%;
	margin-top: 0.7em;
	padding: 0.7em;
}

@media screen and (max-width: 600px) {
}
