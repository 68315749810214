.accessories-range h2 {
  font-size: 4em;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 70px;
}

.accessory-category {
  width: 110px;
}

.accessories-slider {
  justify-content: center;
  gap: 2em;
}

.accessories-range-sub-heading {
  /* margin-left: 1.8vmax !important; */
  font-size: 1.5em;
}
.accessories-range-card {
  background-color: #effbff;
}

.backpack-card {
  margin-left: auto;
}

.mount-card {
  margin-right: auto;
}

.accessories-range-card p {
  font-weight: 600;
}
.variant-border {
  border: 2px solid grey;
  border-radius: 5px;
}

.buy-btns-bag {
  top: 53.5%;
  left: 58%;
  width: 25%;
}

.buy-btns-holder {
  top: 70%;
  left: 62%;
  width: 25%;
}

@media only screen and (max-width: 700px) {
  .accessory-category {
    min-width: 80px;
  }

  .accessories-slider {
    justify-content: start;
    gap: 1em;
  }

  .accessory-category p {
    font-size: 0.8em;
  }

  .bag {
    width: 90% !important;
  }
  .holder {
    width: 90% !important;
  }

  .backpack-card {
    margin: auto;
  }

  .mount-card {
    margin: auto;
  }

  .holderDiv {
    flex-direction: column !important;
  }
  .accessories-range h2 {
    font-size: 2em;
    margin-bottom: 0px;
    padding-bottom: 0px;
    line-height: 30px;
  }

  .accessories-range-sub-heading {
    margin-left: 0 !important;
    font-size: 1em;
  }
  .accessories-range-card p {
    font-weight: 600;
  }

  .buy-btns-bag {
    top: 88%;
    width: 75%;
    transform: translateX(-50%);
    left: 50%;
  }

  .buy-btns-holder {
    top: 88%;
    width: 75%;
    transform: translateX(-50%);
    left: 50%;
  }
}
