.banner-div-curved {
  bottom: -5.5%;
  left: 50%;
  transform: translateX(-50%);
}

.banner-div-curved h1 {
  font-family: "Metropolis-bold";
  font-size: 1.6em;
}

.custom-bullet {
  color: #004dac;
  font-size: 2em;
}

.custom-bullet p {
  color: black;
  font-size: 0.5em;
}

.cycle-vs-ecycle-line {
  width: 100%;
  height: 1px; /* Adjust the height to make the line thinner or thicker */
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #000000 35%,
    #000000 68%,
    #ffffff 100%
  );
}

.cycle-vs-ecycle-line-2 {
  width: 100%;
  height: 1px; /* Adjust the height to make the line thinner or thicker */
  background: linear-gradient(to right, #000000 0%, #ffffff 100%);
}

.banner-div-curved div {
  border: white 12px solid;
  border-radius: 50px;
}

.comparison-landing .comparison-div .first-div {
  border-right: 2px solid black;
}

.comparison-points ul {
  /* list-style: none; */
}

.review-1-background {
  background: linear-gradient(to bottom, #292407 0%, #161303 100%);
}

.review-2-background {
  background: linear-gradient(to bottom, #36060a 0%, #1e0205 100%);
}

.review-3-background {
  background: linear-gradient(to bottom, #061a31 0%, #020c17 100%);
}

.review-4-background {
  background: linear-gradient(to bottom, #062d19 0%, #01130a 100%);
}

.comparison-points ul {
  padding-left: 1em;
}

.usp-comparison h3 {
  font-size: 1.2em;
}

.usp-comparison-sub-div {
  gap: 1.5em;
}

.usp-comparison-img {
  width: 20%;
}

.banner-cta-text {
  top: 10%;
  left: 55%;
}

.features-comparison .inside-div h2 {
  font-size: 1.2em;
}

.features-comparison video {
  width: 150px;
}

.feature-comparison .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 0.6rem !important;
  font-weight: bolder !important;
  color: #004dac !important;
}

.feature-comparison .swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 0.6rem !important;
  font-weight: bolder !important;
  color: #004dac !important;
}

.feature-comparison .swiper-button-prev,
.swiper-button-next {
  border-radius: 100px;
  border: 3px solid #004dac;
  width: 1.9rem !important;
  height: 1.9rem !important;
  color: #004dac;
}

.feature-comparison .swiper-button-next,
.swiper-button-prev {
  border-radius: 100px;
  border: 3px solid #004dac;
  width: 1.9rem !important;
  height: 1.9rem !important;
  color: #004dac;
}

.cycle-vs-ecycle-faq .accordion-button:not(.collapsed) {
  color: black;
  font-weight: bold;
  box-shadow: none;
  background-color: white;
}

.cycle-vs-ecycle-faq .accordion-button {
  color: black;
}

.cycle-vs-ecycle-faq .accordion-button:not(.collapsed)::after {
  background-image: black;
}

.cycle-vs-ecycle-faq .accordion-item {
  color: black;
  border: none !important;
}

.cycle-vs-ecycle-faq .accordion-button:focus {
  box-shadow: none;
}

@media screen and (max-width: 1500px) {
  .features-comparison .inside-div h2 {
    font-size: 1em;
  }

  .features-comparison video {
    width: 100px;
  }

  .banner-cta-text h1 {
    font-size: 2em;
  }
}

@media screen and (max-width: 640px) {
  .ecycle-cycle-usp {
    flex-direction: column;
  }
  .banner-div-curved h1 {
    font-size: 1.11em;
  }

  .banner-div-curved {
    bottom: -8.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    width: 100%;
  }

  .banner-div-curved div {
    border: none;
    border-radius: 50px;
  }

  .comparison-landing .comparison-div {
    flex-direction: column;
    gap: 1em;
  }

  .comparison-landing .comparison-div .first-div {
    border-right: none;
  }

  .comparison-points ul {
    font-size: 0.9em;
  }

  .cycle-vs-ecycle h2 {
    font-size: 1.3em;
  }

  .usp-comparison h3 {
    font-size: 0.75em;
    font-family: "Metropolis-bold";
  }

  .usp-comparison-sub-div {
    gap: 0.5em;
  }

  .usp-comparison-img {
    width: 17%;
  }

  .banner-cta-text-mb {
    top: 7%;
  }

  .banner-cta-btn-mb {
    bottom: 10%;
  }
}
