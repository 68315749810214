.emi-form input {
	background-color: transparent;
	padding: 0.5em;
	font-size: 1.5em;
	border: 0px;
	margin-bottom: 1.95em;
	font-weight: 600;
	padding-bottom: 0.2em;
	color: #232427;
	outline: 0px;
	width: 90%;
	border-bottom: 1px solid rgb(170, 168, 168);
}

.emi-form select {
	background-color: transparent;
	padding: 0.5em;
	font-size: 1.5em;
	border: 0px;
	margin-bottom: 1.95em;
	font-weight: 600;
	padding-bottom: 0.2em;
	color: #232427;
	outline: 0px;
	width: 90%;
	border-bottom: 1px solid rgb(170, 168, 168);
}


.emi-form option:active{
    background-color: grey;
    font-weight: 900;
    border: 0px;
}



.emi-form input::placeholder {
	letter-spacing: 3px;
	

}


@media screen and (max-width: 600px) {

	
	.emi-form input {
		background-color: transparent;
		padding: 0.5em;
		font-size: 1em;
		border: 0px;
		margin-bottom: 1.7em;
		font-weight: 600;
		padding-bottom: 0.2em;
		color: #232427;
		outline: 0px;
		width: 100%;
		border-bottom: 1px solid rgb(170, 168, 168);
	}
	
	.emi-form select {
		background-color: transparent;
		padding: 0.5em;
		font-size: 1em;
		border: 0px;
		margin-bottom: 1.5em;
		font-weight: 600;
		padding-bottom: 0em;
		color: #232427;
		outline: 0px;
		width: 100%;
		border-bottom: 1px solid rgb(170, 168, 168);
	}
	
	
	.emi-form option:active{
		background-color: grey;
		font-weight: 900;
		border: 0px;
	}
	
	
	
	.emi-form input::placeholder {
		letter-spacing: 3px;
		
	
	}
	
		
		
	  }