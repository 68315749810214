.know-your-cycle-div {
  height: 100vh;
}
.select-menu-div {
  top: 65%;
  left: 10.7%;
}
.form-menu {
  gap: 1.4em;
}
.form-select-menu {
  border-radius: 13px !important;
  border: 1px black solid;
}
.form-select-menu:focus {
  border-color: black;
  box-shadow: none;
}
.form-select-menu option {
  min-height: 2em;
}
.btn-banner {
  border-radius: 13px !important;
  background-color: #004dac;
  color: white !important;
}
.btn-banner:hover {
  background-color: black !important;
}

.bike-info-cards {
  background-color: #f6f6f6;
  font-size: 1.2em;
  border-radius: 13px;
  box-shadow: 0px 4px 10px rgba(0, 77, 172, 0.5);
}

.bike-info-cards-desktop {
  width: 230px;
  height: 230px;
}

.bike-bottom-card-desktop {
  width: 490px;
  height: 150px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1em;
}

.bike-heading-main {
  font-size: 2.4em;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1500px) {
  .bike-info-cards-desktop {
    width: 200px;
    height: 200px;
  }
  .bike-bottom-card-desktop {
    width: 430px;
    height: 120px;
  }
  .bike-info-cards {
    font-size: 1.1em;
  }
  .bike-heading-main {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 640px) {
  .select-menu-div {
    top: 40%;
    left: 10.7%;
  }
  .form-menu {
    gap: 1em;
  }
  .bike-info-cards {
    font-size: 0.9em;
  }
  .bike-heading-main {
    font-size: 1.2em;
    transform: none;
  }
}
