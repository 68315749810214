.tRexFamily-primary-bg {
  background-color: #dddddd;
}

.tRexFamily-secondary-bg {
  background: linear-gradient(to bottom right, #e6e6e6 0%, #f9f9f9 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-div-tRex .accordion-button:not(.collapsed) {
  color: black;
  font-weight: bold;
  box-shadow: none;
  background-color: white;
}

.faq-div-tRex .accordion-button {
  color: black;
}

.faq-div-tRex .accordion-button:not(.collapsed)::after {
  background-image: black;
}

.faq-div-tRex .accordion-item {
  color: black;
  border: none !important;
}

.faq-div-tRex .accordion-button:focus {
  box-shadow: none;
}

.faq-div-tRex {
  background-color: white;
}

.comparison-div-tRex {
  width: 100%;
  overflow-x: hidden;
}

.scrollable-container {
  display: flex;
  gap: 1.5em;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.tRexFamily-secondary-bg {
  min-width: 80%;
  scroll-snap-align: start;
}

.comparison-flex-div {
  gap: 2.5em;
}

.btn-know-more {
  background-color: white;
  font-family: "Metropolis-medium";
  font-size: 0.84em;
  display: flex;
  align-items: center;
  width: 135px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.3em 0em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-know-more:hover {
  background-color: black;
  color: white;
}

.btn-buy-now {
  background-color: #2997ff;
  color: white;
  font-family: "Metropolis-medium";
  font-size: 0.84em;
  display: flex;
  align-items: center;
  width: 135px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.3em 0em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-buy-now:hover {
  background-color: white;
  color: #2997ff;
}

.spec-comparison-section {
  height: 130px;
}

:root {
  --cards: 4;
  --cardHeight: 87vh;
  --cardTopPadding: 1.5em;
  --cardMargin: 4vw;
}

.container {
  width: 90%;
  margin: 0 auto;
}

#video-cards {
  list-style: none;
  padding-left: 0;
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight)); */
  /* gap: var(--cardMargin);
  padding-bottom: calc(var(--cards) * var(--cardTopPadding));
  margin-bottom: var(--cardMargin); */
}

#card1 {
  --index: 1;
}
#card2 {
  --index: 2;
}
#card3 {
  --index: 3;
}
#card4 {
  --index: 4;
}

.video-card {
  position: sticky;
  top: 11%;
  padding-bottom: 2em;
}

.video-card-body {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.t-rexfamily-banner-text {
  bottom: 3%;
}

.video-card-body {
  position: relative;

  .overlay {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
}

.addition-card:hover {
  transform: scale(1.07);
}
.addition-card {
  cursor: pointer;
  transition: transform 0.5s;
}

@media (min-width: 650px) {
  .tRexFamily-secondary-bg {
    min-width: auto;
    flex: 1;
  }
}

@media (max-width: 650px) {
  .video-card-body {
    .text-overlay h3 {
      font-size: 1.1em;
    }
  }
  .video-card-body {
    .text-overlay p {
      font-size: 0.8em;
    }
  }
  .comparison-div-tRex {
    overflow-x: scroll;
  }
  .comparison-flex-div {
    gap: 1.5em;
  }
  .btn-know-more {
    font-size: 0.8em;
  }
  .btn-buy-now {
    font-size: 0.8em;
  }
  .spec-comparison-section {
    height: 100px;
  }
  .faq-div-tRex .accordion {
    --bs-accordion-btn-icon-width: 0.9em;
    font-size: 0.9em;
  }
  .accordion-button {
    font-size: 0.6em;
  }
  .addition-card {
    transition: none;
  }
  .addition-card:hover {
    transform: none;
  }
}
