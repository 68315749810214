.doodle-banner-btn {
  position: absolute;
  top: 55%;
  left: 12.5%;
}

.news-carousel {
  background-color: #004dac;
}

.modal-video-mahi {
  height: 560px;
  width: 315px;
}

.news-carousel .splide__pagination__page.is-active {
  border-radius: 50% !important;
  height: 10px !important;
  width: 10px !important;
  background: white !important;
}

.card-doodle {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  transition: all 1.2s ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}

.play-icon-dhoni {
  position: absolute;
  top: 50% !important;
  right: 30% !important;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.news-desc {
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: justify;
}
.ms-text {
  background-color: #ffffff;
  border-radius: 10px;
  width: 70%;
  top: 45%;
  left: 3%;
}

@media screen and (max-width: 600px) {
  .doodle-banner-btn {
    position: static;
    display: flex;
    justify-content: center;
  }
  .dhoni-banner {
    padding-bottom: 4rem;
  }
  .ms-text-mobile {
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -5%);
  }
  .play-icon-dhoni {
    position: absolute;
    top: 50% !important;
    right: 33% !important;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
