.slash-bike-price {
  font-size: 1.7em;
  font-family: "Metropolis-bold";
  color: grey;
}

.bike-price {
  font-size: 1.4em;
  font-family: "Metropolis-bold";
}

.card-bike-name {
  font-family: "Metropolis-bold";
}

.save-percent-product-screen {
  color: #00d100;
  font-weight: bold;
}

.usecase-text {
  width: 80%;
  font-family: Metropolis-bold;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #d9d9d9;
  padding: 1rem;
}

.testimonials-gift {
  background-color: #004dac;
}

.testimonials-card {
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.heading-giftpage {
  font-size: 1.8em;
}

.corporateGiftingForm-desktop {
  overflow-x: hidden;
  background-image: url(https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/WEB+1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  object-fit: cover;
  height: 850px;
  padding-top: 40px;
}
.giftingForm {
  background-color: white;
  border-radius: 10px;
  width: 23vmax;
}
.giftingHeading {
  font-family: "Metropolis-black";
  font-size: 3.2em;
}
.giftingSubHeading {
  font-family: "Metropolis-black";
  font-size: 2.8em;
}
.giftingSubHeading1 {
  font-size: 1.2em;
}
.giftingsubHeading,
.giftingformHeading {
  font-family: "Metropolis-bold";
}
.giftingForm > button {
  text-align: center;
  width: 50%;
}

.testRideContentDiv {
  padding: 2vmax;
}

.usecase-text {
  font-size: 1.2em;
}

.giftBtn {
  border-radius: 25px;
}

.gradient-grey {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background: linear-gradient(to bottom, transparent, black);
}

.gift-grid-text {
  font-family: "Metropolis-medium";
}

.gift-reviews {
  background-color: #cfcfcf;
}

.heading-text-gifting {
  font-size: 1.6em;
}

.gift-banner-text {
  top: 10%;
  left: 2.7%;
  transform: translate(-50, -50);
}

.gift-banner-form {
  top: 10%;
  right: 3%;
  transform: translate(-50, -50);
}

.gifting-form-div-main {
  background-color: #e9e9e9;
  border: 1px solid #004dac;
  /* box-shadow: 2px 2px 8px rgb(199, 193, 193), -2px 2px 8px rgb(199, 193, 193); */
  border-radius: 10px;
}

.submit-btn-gift {
  color: #fff !important;
  background-color: #004dac !important;
  padding: 0.5em;
  font-weight: 600;
}

.gifting-form-div-main .form-input {
  width: 100%;
  border-radius: 8px;
  outline: 0px;
  border: 0.2px solid #aaa1a1;
  padding: 8px;
  padding-left: 15px;
  background-color: #f4f4f4;
}

.gifting-form-div-main small {
  font-size: 0.8em;
  display: block;
  text-align: center;
}

.gifting-form-div-main button {
  border-radius: 25px;
}

.testimonials-format {
  display: flex;
}

.support-img,
.support-div {
  width: 134px;
}

@media screen and (max-width: 1720px) {
  .giftingHeading {
    font-size: 3em;
  }
  /* .giftingSubHeading {
    font-size: 2.3em;
  } */
}

@media screen and (max-width: 1620px) {
  .giftingHeading {
    font-size: 2.8em;
  }
  .giftingSubHeading1 {
    font-size: 1.1em;
  }
  .giftingtext-d {
    font-size: 0.9em;
  }
  .gift-banner-text {
    top: 10%;
    left: 4%;
    transform: translate(-50, -50);
  }

  /* .giftingSubHeading {
    font-size: 2em;
  } */
}

@media screen and (max-width: 1520px) {
  .giftingHeading {
    font-size: 2.5em;
  }
  .giftingtext-d {
    font-size: 0.85em;
  }
  .giftingSubHeading {
    font-size: 2.7em;
  }
  .gift-banner-text {
    top: 10%;
    left: 3.5%;
    transform: translate(-50, -50);
  }
}

@media screen and (max-width: 1420px) {
  .giftingtext-d {
    font-size: 0.8em;
  }
  .usecase-text {
    width: 80%;
  }
  .gift-banner-text {
    top: 10%;
    left: 3.2%;
    transform: translate(-50, -50);
  }
  .heading-text-gifting {
    font-size: 1.4em;
  }
  .gift-banner-text {
    top: 7%;
  }

  .gift-banner-form {
    top: 7%;
  }
  /* .input-gifting {
    font-size: 0.85em;
    padding: 0.4em 0.7em !important;
  } */
  .giftingHeading {
    font-size: 2.3em;
  }
  .giftingSubHeading1 {
    font-size: 1.05em;
  }
  .support-img,
  .support-div {
    width: 110px;
  }
  .support-div p {
    font-size: 0.8em;
  }
  /* .giftingSubHeading {
    font-size: 1.9em;
  } */
}

@media screen and (max-width: 1320px) {
  .gift-banner-text {
    top: 10%;
    left: 3.5%;
    transform: translate(-50, -50);
  }
  /* .input-gifting {
    font-size: 0.85em;
    padding: 0.25em 0.7em !important;
  } */
  .gift-products .swiper-button-prev {
    top: 7% !important;
    left: 94% !important;
    transform: translate(-50%, -50%);
  }
  .giftingHeading {
    font-size: 2.3em;
  }
  .giftingSubHeading {
    font-size: 2.3em;
  }
  .giftingSubHeading1 {
    font-size: 1.14em;
  }
}

@media screen and (max-width: 700px) {
  .heading-giftpage {
    font-size: 1.4em;
  }
  .testimonials-format {
    flex-direction: column;
  }
  .support-img,
  .support-div {
    width: 80px;
  }
  .support-text {
    font-size: 0.6em;
  }
  .reviews-desc {
    font-size: 0.9em;
  }
  .creds {
    font-size: 0.85em;
  }
}
