.about-banner {
  height: 95vh;
  background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/about/About+US_1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.about-banner div {
  position: absolute;
  top: 50%;
  left: 20%;
  translate: -20% -50%;
}
.about-banner div h1 {
  color: white;
  font-weight: 900;
  font-size: 4em;
}
.about-banner div p {
  color: white;

  font-size: 1.5em;
}
/* .................................... */

.about-content p {
  font-size: 0.9em;
}

/* .................... */

.founders {

}

.founders h1 {
  color: white;
  font-weight: 900;
  text-align: center;
}

.love-officers {
  background-color: white;
  padding-top: 5em;
  padding-bottom: 5em;
}

.love-officers h1 {
  color: black;
  font-weight: 900;
  text-align: center;
}

.founder-cards {
  margin-top: 5em;
}
.founder-card {
  margin: auto;
}
.founder-card img {
  border-radius: 5px;
}

.founder-name {
  color: #004dac;

  margin-top: 0.7em;
  margin-bottom: 0px;
  font-family: Metropolis-bold;
  font-size: 3em;
}
.founder-title {
  font-size: 1em;
  margin-top: 0.2em;
 color: #004dac;
  margin-bottom: 0.5em;
}
.founder-desc {
  font-size: 1em;
  margin-top: 0.2em;
  width: 65%;
 
  color: rgba(18, 19, 19, 0.678);
}

.chief-cards {
  margin-top: 5em;
}
.chief-card {
  margin: auto;
}
.chief-card img {
  border-radius: 5px;
}

.chief-name {
  color: black;
  font-weight: 600;
  margin-top: 0.7em;
  margin-bottom: 0px;
}
.chief-title {
  font-size: 0.8em;
  margin-top: 0.2em;
  color: #242424;
  margin-bottom: 0.5em;
}
.chief-desc {
  font-size: 0.8em;
  margin-top: 0.2em;
  padding-right: 1em;
  color: #242424;
}

/* ................................... */

.milestones {
  padding-top: 5em;
  padding-bottom: 5em;
}
.milestone {
  padding: 4em;
}
.milestone:nth-child(1) {
  border-right: 2px solid rgb(94, 92, 92);
  border-bottom: 2px solid rgb(94, 92, 92);
}
.milestone:nth-child(2) {
  border-bottom: 2px solid rgb(94, 92, 92);
}
.milestone:nth-child(3) {
  border-bottom: 2px solid rgb(94, 92, 92);
  border-left: 2px solid rgb(94, 92, 92);
}
.milestone:nth-child(4) {
  border-right: 2px solid rgb(94, 92, 92);
}

.milestone:nth-child(6) {
  border-left: 2px solid rgb(94, 92, 92);
}

.milestones h1 {
  color: rgb(0, 0, 0);
  font-weight: 900;
  text-align: center;
  margin-bottom: 3em;
}
.milestone-value {
  font-size: 3em;
  font-weight: 900;
  color: rgb(41, 40, 40);
  text-align: center;
}
.milestone-heading {
  font-size: 1em;
  color: rgb(54, 53, 53);
  font-weight: 900;
  text-align: center;
}

/* .................................... */

.our-team {
  height: 90vh;
  background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/etc-pages-banners/ABout+Us.jpg");
  background-size: cover;
  background-position: center;
}

.our-team h1 {
  font-size: 5em;
  color: white;
  text-shadow: 4px 4px 2px rgb(100, 95, 95);
  font-weight: 900;
  position: absolute;
  margin: 0px;
  top: 15%;
  left: 50%;
  translate: -50% -15%;
}

@media only screen and (max-width: 700px) {
  .about-banner {
    height: 75vh;
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/about/AU-Banner_Mobile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about-banner div {
    position: absolute;
    top: 75%;
    left: 0%;
    width: 100%;
    translate: 0% -75%;
  }
  .about-banner div h1 {
    color: white;
    font-weight: 900;
    font-size: 2em;
    text-align: center;
  }
  .about-banner div p {
    color: white;
    text-align: center;
    font-size: 1em;
  }

  .about-content p {
    font-size: 0.9em;
  }

  /* .................... */

  .founders {
 background-size: fill;
  }

  .founders h1 {
    color: white;
    font-weight: 900;
    text-align: center;
  }

  .love-officers {
    background-color: white;
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .love-officers h1 {
    color: black;
    font-weight: 900;
    text-align: center;
  }

  .founder-cards {
    margin-top: 1em;
  }
  .founder-card {
    margin: auto;
  }
  .founder-card img {
    border-radius: 5px;
  }

  .founder-name {
    color: white;
    font-weight: 600;
    margin-top: 0.7em;
    margin-bottom: 0px;
    font-size: 1em;
  }
  .founder-title {
    font-size: 0.8em;
    margin-top: 0.2em;
    color: rgb(242, 243, 243);
    margin-bottom: 0.5em;
  }
  .founder-desc {
    font-size: 0.8em;
    margin-top: 0.2em;
    padding-right: 1em;
    color: rgba(242, 243, 243, 0.678);
  }

  .chief-cards {
    margin-top: 1em;
  }
  .chief-card {
    margin: auto;
  }
  .chief-card img {
    border-radius: 5px;
  }

  .chief-name {
    color: black;
    font-weight: 600;
    margin-top: 0.7em;
    margin-bottom: 0px;
  }
  .chief-title {
    font-size: 0.8em;
    margin-top: 0.2em;
    color: #242424;
    margin-bottom: 0.5em;
  }
  .chief-desc {
    font-size: 0.8em;
    margin-top: 0.2em;
    padding-right: 1em;
    color: #242424;
  }

  /* ................................... */

  .milestones {
    padding: 1.5em;
    padding-top: 3em;

    padding-bottom: 3em;
  }
  .milestone {
    padding: 2em;
    padding-bottom: 1em;
  }
  .milestone:nth-child(1) {
    border-right: 1px solid rgb(94, 92, 92);
    border-bottom: 1px solid rgb(94, 92, 92);
  }
  .milestone:nth-child(2) {
    border-bottom: 1px solid rgb(94, 92, 92);
  }
  .milestone:nth-child(3) {
    border-bottom: 1px solid rgb(94, 92, 92);
    border-left: none;

    border-right: 1px solid rgb(94, 92, 92);
  }
  .milestone:nth-child(4) {
    border-bottom: 1px solid rgb(94, 92, 92);
    border-right: none;
  }

  .milestone:nth-child(5) {
    border-right: 1px solid rgb(94, 92, 92);
    border-left: none;
  }
  .milestone:nth-child(6) {
    border-left: none;
    border-right: none;
  }

  .milestones h1 {
    color: rgb(0, 0, 0);
    font-weight: 900;
    text-align: center;
    margin-bottom: 1.5em;
  }
  .milestone-value {
    font-size: 2em;
    font-weight: 900;
    color: rgb(41, 40, 40);
    text-align: center;
  }
  .milestone-heading {
    font-size: 0.8em;
    color: rgb(54, 53, 53);
    font-weight: 900;
    text-align: center;
  }
  /* .................. */
  .our-team {
    height: 40vh;
    background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/etc-pages-banners/ABout+Us.jpg");
    background-size: cover;
    background-position: center;
  }
  .our-team h1 {
    font-size: 1.5em;
    color: white;
    font-weight: 900;
    position: absolute;
    margin: 0px;
    top: 50%;
    left: 0%;
    width: 100%;
    text-align: center;
    translate: 0% -50%;
  }
}
