@import url("https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/fonts/spy-agency/stylesheet.css");

.section-1-overlay-mb {
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0.7316176470588236) 35%,
		rgba(0, 0, 0, 0.560749299719888) 54%,
		rgba(0, 0, 0, 0.38707983193277307) 77%,
		rgba(0, 0, 0, 0) 100%
	);
}

.bike-heading {
	font-family: spy_agency_bold_italicBdIt,
		sans-serif;
	font-size: 2em;
}

.bike-color {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.bike-box {
	margin-top: 9em;
}

@media only screen and (max-width: 700px) {
	.bike-box {
		margin-top: 2em;
	}
}
