.timer-passion-movements-product {
  font-family: "Metropolis-black";
  font-size: 1em;
  text-transform: uppercase;
  color: #004dac;
}

.bike-product-images {
  min-height: 92vh;
}
.comparison-feature-block {
  height: 100px;
}
.bike-product-images .swiper-button-next:after,
.bike-product-images .swiper-button-prev:after {
  font-size: 0.9rem !important;
  font-weight: bolder !important;
  color: #004dac !important;
}

.product-select .form-select:focus {
  border: none;
  box-shadow: none;
}

.product-select .form-select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
}

.product-select select {
  color: #ffffff;
}

.bike-product-images .swiper-button-next,
.bike-product-images .swiper-button-prev {
  /* padding: 15px; */
  border-radius: 100px;
  border: 2px solid #004dac;
  width: 2rem !important;
  height: 2rem !important;
  color: #004dac;
}
.bike-product-images img {
  width: 76%;
}

.active-color {
  border: 3px solid #004dac !important;
}
.play-icon-container-product {
  position: absolute;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.slider-count {
  top: 3%;
  z-index: 3;
  right: 3%;
}

.slider-count h3 {
  font-size: 1.5em;
}

.product-video {
  width: 100%;
}

.product-gif {
  position: relative;
}

.product-gif-text {
  position: absolute;
}

.structure-design {
  padding: 1rem;
  background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Checker_BG.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.compare-div {
  padding: 1rem;
}

.product-gif-specs {
  display: flex;
}

.product-gif-heading {
  position: absolute;
  top: 5%;
  left: 3%;
  text-align: center; /* Center the text horizontally */ /* Set the width to adjust the centering */
  margin-left: auto; /* Align the div to the center */
  margin-right: auto;
  z-index: 5;
}

.product-gif-heading h2 {
  color: #ffffff;
  font-weight: 900;
  font-size: 2.6rem;
  z-index: 2;
}

.product-gif-specs {
  width: 100%;
  position: absolute;
  top: 81%;
  right: 2%;
  z-index: 1;
}

.spec-box {
  flex: 1;
  height: 100px;
}

.spec-box h3 {
  font-size: 2em;
  font-weight: 900;
  color: #ffffff;
}

.spec-box p {
  font-weight: 600;
  color: #ffffff;
  font-size: 1.3rem;
}

.spec-divider {
  width: 2px;
  background-color: #ffffff;
  height: 5.5rem;
  margin: 0 10px;
}

.structure-sub-heading {
  font-weight: 600;
}

.tool-tip {
  margin-top: 4.1rem;
  margin-bottom: 4.1rem;
}

.line-break {
  background: #eeeeee;
  height: 0.8rem;
}

.specs-heading {
  font-size: 1.24rem;
  font-weight: 600;
}

.specs-sub-heading {
  font-size: 1.04rem;
  font-weight: 600;
}

.question {
  padding-left: 1rem;
  margin: 0;
  display: block;
  cursor: pointer;
  font-weight: 600;
}

.answers {
  display: none;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

.questions:checked ~ .answers {
  display: block;
  opacity: 1;
  padding: 15px;
}

.questions {
  display: none;
}

.spec-btn {
  border: 1px solid black;
  background: transparent;
  color: black;
  padding: 0.4em 1.6em;
}

.reviews-logo {
  width: 45px;
}
.star-logo {
  width: 15px;
}
.reviews-logo-div {
  padding-left: 1rem;
}

.read-button {
  border: 2px solid black;
  border-radius: 25px;
  color: black;
  font-weight: 600;
  font-size: 1em;
}

.read-button:hover {
  border: 2px solid #ffffff;
  background-color: black;
  border-radius: 25px;
  color: #ffffff;
  font-weight: 600;
  font-size: 1em;
}

@media screen and (max-width: 600px) {
  .timer-passion-movements-product {
    font-size: 0.75em;
  }
  .specs-feature {
    font-size: 0.9rem;
  }
  .product-gif-text h2 {
    font-size: 1.3em;
    font-weight: 600;
  }
  .product-text {
    font-size: 0.8em;
  }
  .reviews-text {
    font-size: 0.9em;
  }
  .structure-main-heading {
    font-size: 1.24rem;
  }

  .structure-sub-heading {
    font-size: 1.1rem;
  }

  .desktop-video-text {
    display: none;
  }

  .product-gif-heading h2 {
    font-size: 1.4em;
  }

  .product-gif-specs {
    top: 60%;
  }
  .spec-box h3 {
    font-size: 1.2em;
  }
  .spec-divider {
    height: 3em;
    background-color: #ffffff;
  }

  .dimension-image {
    display: none;
  }
  .star-logo {
    width: 12px;
  }
  .reviews-logo {
    width: 23px;
  }
  .reviews-logo-div {
    padding-left: 0.3rem;
  }
  .review-logo-text {
    font-size: 0.53rem;
  }
  .reviews-sub-div {
    margin-bottom: 0.8rem;
  }
  .reviews-logo-sec {
    display: none;
  }
}
