.bolt {
    --bolt: rgb(242, 222, 16);
    width: 126px;
    height: 186px;
    position: relative;
    svg {
        position: absolute;
        display: block;
        stroke-width: 4;
        fill: none;
        stroke-linecap: round;
        stroke: var(--bolt);
        &.circle {
            left: 7px;
            top: 100%;
            width: 112px;
            height: 44px;
            stroke-dashoffset: 179px;
            stroke-dasharray: 0px 178px
        }
        &.line {
            --r: 0deg;
            top: 95%;
            width: 70px;
            height: 3px;
            stroke-dashoffset: 71px;
            stroke-dasharray: 0px 70px;
            transform: rotate(var(--r));
            &.left {
                --r: 130deg;
                left: -24px;
            }
            &.right {
                --r: 40deg;
                right: -24px;
            }
        }
        &.white {
            --r: 0deg;
            --s: 1;
            top: 30%;
            z-index: 1;
            stroke: #fff;
            stroke-dashoffset: 241px;
            stroke-dasharray: 0px 240px;
            transform: rotate(var(--r)) scaleX(var(--s));
            &.left {
                --r: -20deg;
                left: 0;
            }
            &.right {
                --r: 20deg;
                --s: -1;
                right: 0;
            }
        }
    }
    div {
        display: block;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 44%;
        }
        &:before {
            width: 112px;
            height: 112px;
            margin: -56px 0 0 -56px;
            background: #fff;
            filter: blur(124px);
        }
        &:after {
            width: 64px;
            height: 64px;
            margin: -32px 0 0 -32px;
            background: #FFF9BC;
            z-index: 1;
            filter: blur(60px);
        }
        span {
            display: block;
            width: 106px;
            height: 186px;
            background: #00ff7f;
            clip-path: polygon(80% 0%, 80% 0, 60% 45%, 90% 45%, 20% 100%, 40% 57%, 10% 57%);
        }
    }
    &.animate {
        div {
            &:before,
            &:after {
                animation: shine 2s ease;
            }
            span {
                animation: morph 2s ease;
            }
        }
        svg {
            &.circle {
                animation: circle .45s cubic-bezier(0.77, 0, 0.175, 1) forwards 1.3s;
            }
            &.line {
                animation: line .45s cubic-bezier(0.77, 0, 0.175, 1) forwards 1.3s;
            }
            &.white {
                animation: white .45s cubic-bezier(0.77, 0, 0.175, 1) forwards 1.45s;
                &.right {
                    animation-delay: 1.6s;
                }
            }
        }
    }
}

@keyframes circle {
    100% {
        stroke-dasharray: 178px 178px
    }
}

@keyframes white {
    100% {
        stroke-dasharray: 240px 240px
    }
}

@keyframes line {
    100% {
        stroke-dasharray: 70px 70px
    }
}

@keyframes shine {
    30%,
    70% {
        opacity: 0;
    }
}

@keyframes morph {
    12% {
        clip-path: polygon(24% 50%, 100% 0, 65% 40%, 65% 40%, 8% 100%, 24% 50%, 24% 50%);
    }
    24%,
    72% {
        clip-path: polygon(36% 40%, 82% 40%, 82% 40%, 82% 40%, 36% 71%, 36% 40%, 36% 40%);
    }
    84% {
    clip-path: polygon(24% 50%, 100% 0, 65% 40%, 65% 40%, 8% 100%, 24% 50%, 24% 50%);
    }
}

.loder-main-div{
         position: absolute;
            top: 40%;
            left: 50%;
            translate: -40% -50%;
    }

.typewriter{
     position: absolute;

            top: 60%;
        
            left:50%;
            translate: -50% -50%;
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 2.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(255, 255, 255); }
}



 @media only screen and (max-width: 1600px) {
    .bolt{
    scale: 0.8;
}

.loder-main-div{
         position: absolute;
            top: 30%;
            left: 50%;
            translate: -40% -50%;
    }
.typewriter{
     position: absolute;

            top: 50%;
        
            left:50%;
            translate: -50% -50%;
}

    
.typewriter h1 {
  font-size: 1.2em;
  text-align: center;
  overflow: hidden;
  /*Ensuresthecontentisnotrevealeduntiltheanimation*/border-right: .15em solid orange;
  /*Thetypwritercursor*/white-space: nowrap;
  /*Keepsthecontentonasingleline*/margin: 0 auto;
  /*Givesthatscrollingeffectasthetypinghappens*/letter-spacing: .15em;
  /*Adjustasneeded*/animation:     typing 2s steps(30, end),    blink-caret .75s step-end infinite;
  -webkit-animation:;
}
 }


@media only screen and (max-width: 700px) {

    .loder-main-div{
         position: absolute;
            top: 30%;
            left: 53%;
            translate: -50% -50%;
    }

    .loder-text{
            position: absolute;
           
}


.typewriter{
     position: absolute;

      top: 50%;
         text-align: center;
            width: 50%;
            left:35%;
            text-align: center;
            translate: -30% 0%;
}

.typewriter h1 {
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  /*Ensuresthecontentisnotrevealeduntiltheanimation*/border-right: .15em solid orange;
  /*Thetypwritercursor*/white-space: nowrap;
  /*Keepsthecontentonasingleline*/margin: 0 auto;
  /*Givesthatscrollingeffectasthetypinghappens*/letter-spacing: .15em;
  /*Adjustasneeded*/animation:     typing 2s steps(30, end),    blink-caret .75s step-end infinite;
  -webkit-animation:;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(255, 255, 255); }
}

}