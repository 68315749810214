.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

.popup-container {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  max-width: 800px;
  width: 70%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-content {
  height: 100%;
}

.popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.welcome-popup-input-field {
  border: none;
  text-align: center;
  border-bottom: 1px solid #ced4da !important;
}

.welcome-popup-input-field:focus {
  border: none;
  box-shadow: none;
}

.heading-welcome-popup {
  font-size: 1.6em;
}

.button-welcome-popup {
  background-color: #004dac;
  color: white;
  border: none;
}

.button-welcome-popup:hover {
  background-color: black;
  color: white;
}

/* .text-welcome-popup {
  font-size: 0.9em;
} */

.welcome-popup-input-field {
  font-size: 0.9em;
}

.button-welcome-popup {
  font-size: 0.9em;
}

.popup-close-btn {
  width: 8px;
  height: 8px;
}

@media screen and (max-width: 650px) {
  .heading-welcome-popup {
    font-size: 1.15em;
  }

  .text-welcome-popup {
    font-size: 0.85em;
  }

  .welcome-popup-input-field {
    padding: 0.3em;
    font-size: 0.8em;
  }

  .button-welcome-popup {
    font-size: 0.8em;
  }
  .popup-container {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    max-width: 900px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
}
