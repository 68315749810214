.profile-border-heading {
	font-size: 5em;
	font-weight: 900;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #353b48;
	margin: 0px;
	line-height: 35px;
	color: transparent;
	z-index: -1;
}

.profile-back{
    font-size: 3em;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #353b48;
    margin: 0px;
    line-height: 35px;
    color: transparent;
}



@media screen and (max-width: 600px) {
    .profile-border-heading {
        font-size: 2em;
        font-weight: 900;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #353b48;
        margin: 0px;
        line-height: 35px;
        color: transparent;
        z-index: -1;
    }
    .profile-back{
        font-size: 1em;
        
       
        
    }
}