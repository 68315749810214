.all-bikes-main-banner-btn {
  font-size: 1.3em;
  background-color: #004dac;
  color: #f0f1f5;
  border: 0px;
  outline: 0px;
}

.testride-btn-allbike .btn.disabled {
  color: #ffffff;
}

.outofstock-alert {
  top: 0.6%;
  right: 2%;
}

.outofstock-tag {
  font-size: 0.7em;
}

.usp-font-size {
  font-size: 0.72em;
}

.all-bikes-main-banner-btn:hover {
  background-color: black;
  color: #ffffff;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1rem !important;
  font-weight: bolder !important;
  color: #004dac !important;
}

.swiper-button-next,
.swiper-button-prev {
  /* padding: 15px; */
  border-radius: 100px;
  border: 3px solid #004dac;
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: #004dac;
}

.all-bikes-main-banner-btn-mb {
  width: 130px;
  font-size: 0.9em;
  background-color: #004dac;
  color: #f0f1f5;
  border: 0px;
  outline: 0px;
  border-radius: 20px;
  padding: 0.3em 0.8em;
}

.all-bikes-main-banner-btn-mb:hover {
  background-color: #f0f1f5;
  color: #004dac;
}

.lifestyle-end-banner-btn-div {
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
}

.lifestyle-end-banner-btn {
  font-size: 1.2em;
  background-color: #f0f1f5;
  color: #004dac;
  border: 0px;
  outline: 0px;
  border-radius: 20px;
  padding: 0.5em 1.7em;
}

.lifestyle-end-banner-btn:hover {
  background-color: #004dac;
  color: #f0f1f5;
}

lifestyle-end-banner-btn-div .product-buy-now:hover {
  border-radius: 25px;
}

.desire-main-banner-btn-div {
  position: absolute;
  bottom: 5%;
  left: 28%;
  translate: -50% -50%;
}

.x-factor-main-banner-btn-div {
  position: absolute;
  bottom: 5%;
  left: 72.5%;
  translate: -50% -50%;
}

.all-bikes-bike-heading {
  font-size: 3em;
}

.all-bikes-bike-card-price {
  font-size: 2.5em;
  font-weight: 700;
}
.desire-bike-card-img {
  scale: 1.25;
}

.all-bikes-bike-card-btn {
  width: 100%;
  font-size: 0.9em;
  background-color: #004dac;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 0.9em;
  box-shadow: none;
  margin: auto;
  display: block;
}

.out-stock-btn {
  width: 100%;
  font-size: 0.9em;
  background-color: #242424;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 0.9em;
  box-shadow: none;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1500px) {
  .outofstock-alert {
    top: 0.3%;
    right: 0.2%;
  }
  .outofstock-tag {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 1400px) {
  .outofstock-alert {
    top: 1%;
    right: 1%;
  }
  .all-bikes-screen .outofstock-alert {
    top: 0%;
    right: -1%;
  }
  .outofstock-tag {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 1366px) {
  .outofstock-alert {
    top: 1%;
    right: 1%;
  }
  .all-bikes-screen .outofstock-alert {
    top: 1%;
    right: -1%;
  }
  .outofstock-tag {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 1320px) {
  .outofstock-alert {
    top: 1%;
    right: 1%;
  }
  .all-bikes-screen .outofstock-alert {
    top: 0%;
    right: -1%;
  }
  .outofstock-tag {
    font-size: 0.55em;
  }
}

@media screen and (max-width: 1300px) {
  .outofstock-alert {
    top: 1%;
    right: 2%;
  }
  .outofstock-tag {
    font-size: 0.52em;
  }
}

@media screen and (max-width: 1220px) {
  .outofstock-alert {
    top: 1%;
    right: 2%;
  }
  .outofstock-tag {
    font-size: 0.45em;
  }
}

@media only screen and (max-width: 700px) {
  .usp-font-size {
    font-size: 0.65em;
  }

  .outofstock-alert {
    top: -5%;
    right: -1%;
  }
  .outofstock-tag {
    font-size: 0.5em;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
  #desire {
    padding-left: 1em;
    padding-right: 1em;
  }
  #x-factor {
    padding-left: 1em;
    padding-right: 1em;
  }
  .all-bikes-main-banner-btn {
    font-size: 0.9em;
    background-color: #004dac;
    color: #f0f1f5;
    border: 0px;
    outline: 0px;
  }

  .all-bikes-main-banner-btn:hover {
    background-color: black;
    color: #f0f1f5;
  }
  .all-bikes-main-banner-btn-div {
    position: absolute;
    bottom: 5%;
    left: 50%;
    translate: -50% -50%;
  }

  .desire-main-banner-btn-div {
    position: absolute;
    bottom: 52%;
    left: 50%;
    translate: -50% -50%;
  }

  .x-factor-main-banner-btn-div {
    position: absolute;
    bottom: 0%;
    left: 50%;
    translate: -50% -50%;
  }

  .lifestyle-end-banner-btn-div {
    top: 50%;
  }

  .lifestyle-end-banner-btn {
    font-size: 0.9em;
  }
  .all-bikes-bike-heading {
    font-size: 2em;
    margin-bottom: 0em;
  }

  .all-bikes-bike-card-price {
    font-size: 1.5em;
    font-weight: 700;
  }
  .all-bikes-bike-card-desc {
    font-size: 0.9em;
  }

  .desire-bike-card-img {
    scale: 1.3;
  }
}
