.form-pop-up .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.form-pop-up .modal-content {
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 90%;
  max-width: 500px;
}

.form-pop-up .close-button {
  text-align: right;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.form-pop-up .formPopUpBtn {
  background-color: #004dac;
  border: none;
  border-radius: 2vmax;
}

.form-pop-up .modal-content {
  width: 30%;
}

.form-test-ride-container {
  box-shadow: 2px 2px 8px rgb(199, 193, 193), -2px 2px 8px rgb(199, 193, 193);
}

.features-comparison-kerala .swiperslide-div-kerala {
  height: 300px;
}

@media screen and (max-width: 640px) {
  .form-pop-up .modal-content {
    width: 90%;
  }
}
