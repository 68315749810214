/* @media screen and (min-width: 768px) and (min-width: 992px) {
  .testRideMainDiv > h2 {
    font: 300 2vmax;
  }
  .testRideForm > h6 {
    font: 300 1vmax;
  }
  .testRideForm > input[type="text"],
  input[type="tel"] {
    width: 200px;
  }
  .testRideBtn {
    padding: 0.5vmax 2vmax !important;
    text-align: center;
    margin: 0%;
    font: 300 1vmax;
  }
} */

.testRideMainDiv {
  overflow-x: hidden;
  background-image: url(https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/testRideBannerDesktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  object-fit: cover;
  height: 45vmax;
}

.testRideInnerDivDesktop {
  position: absolute;
  right: 3%;
  top: 13%;
}

.testRideForm {
  width: 23vmax;
}
.testRideHeading {
  font-weight: 600;
}
.testRideSubHeading {
  font-weight: 600;
}
.testRideForm > button {
  text-align: center;
  width: 50%;
}

.testRideContentDiv {
  padding: 2vmax;
}

@media screen and (max-width: 1600px) {
  .testRideMainDiv {
    overflow-x: hidden;
    background-image: url(https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/testRideBannerDesktop.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: relative;
    object-fit: cover;
    height: 50vmax;
  }
  .testRideInnerDivDesktop {
    position: absolute;
    right: -5%;
    top: 10%;
  }
}

@media screen and (max-width: 1400px) {
  .testRideInnerDivDesktop {
    position: absolute;
    right: -6.5%;
    top: 10%;
  }
}

@media screen and (max-width: 600px) {
  .testRideMainDiv {
    position: relative;
    height: 90vmax !important;
    display: flex;
    gap: 15em;
    align-items: left;
    text-align: center;
    flex-direction: column;
    background-image: url(https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/testRideBannerMobile.jpg) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    padding: 0vmax !important;
  }

  .testRideContentDiv {
    width: 100%;
  }
  .testRideForm {
    display: flex;
    justify-content: space-between;
    align-items: left;
    text-align: center;
    flex-direction: column;
    padding: 3vmax;
    width: 100% !important;
  }
  .testRideContentDiv {
    padding: 0vmax !important;
  }
  .testRideBtn {
    margin: auto;
    width: 200px !important;
  }
  .testRideSubHeading {
    text-align: start;
    font: 300 2.3vmax !important;
  }
  .testRideHeading {
    text-align: start;

    padding: 4vmax;
  }

  .testRideForm > input {
    width: 100% !important;
  }
}

/* input[type="text"],
input[type="tel"] {
  background: none;
  color: white;
}

input[type="text"]:focus,
input[type="tel"]:focus {
  background: none;
  color: white;
} */
