.contactus-main h1 {
  font-size: 3em;
  color: white;
  font-weight: 700;
}

.contactus-main .subline {
  color: grey;
}

.partner-img-text {
  z-index: 5;
  top: 40%;
  left: 2.3%;
}

.partner-form-div {
  position: absolute;
  width: 25%;
  z-index: 5;
  top: 70%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.contact-us-countries {
  margin: auto;
  margin-top: 5em;
  margin-bottom: 5em;
  width: 90%;
}

.country p {
  font-weight: 900;
}

.contactus-main .mx-auto {
  width: 60%;
}
.contactus-main button {
  width: 40%;
  padding: 0.9em;
}
.office-location {
  width: 100%;
  height: 40vh;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgb(209, 203, 203);
}

@media only screen and (max-width: 700px) {
  .contactus-main h1 {
    font-size: 2em;
    color: white;
    font-weight: 700;
    margin-top: 1.4em;
  }

  .partner-img-text {
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .partner-img-text h2 {
    font-size: 1.04em;
  }

  .partner-img-text p {
    font-size: 0.52em;
  }

  .contactus-main .mx-auto {
    width: 100%;
    padding: 0.5em;
  }
  .contactus-main button {
    width: 60%;
    padding: 0.7em;
    margin-bottom: 3em;
  }
  .contact-us-countries {
    margin: auto;
    margin-top: 1em;
    margin-bottom: 3em;
    width: 90%;
  }
  .office-location {
    width: 100%;
    height: 25vh;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgb(209, 203, 203);
  }
}
