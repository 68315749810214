.background-cycle {
  background: url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/1920x1080.jpg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.form-book-desktop {
  transform: translateX(-50%, -50%);
  right: 7%;
}

.book-dealer-submit {
  background-color: #004dac !important;
  color: white !important;
}

.book-dealer-submit:hover {
  background-color: #004dac !important;
  color: white !important;
}

.form__input:checked + .form__label-book {
  background-color: #004dac !important; /* Change this to your desired color */
  color: white !important;
}
