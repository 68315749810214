.blog-div:hover {
  .blog-category {
    text-decoration: underline;
  }
  .blog-heading {
    text-decoration: underline;
  }
}

.blog-category-active {
  border-bottom: 2px solid #004dac;
}

.blog-categories {
  gap: 3em;
}

@media screen and (max-width: 660px) {
  .blog-categories {
    gap: 1.3em;
  }
}
