@import url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/fonts/metropolis/style.css");

.landingpagebody {
	font-family: "Metropolis Regular";
	padding: 0px;
	margin: 0px;
}
.landing-nav img {
	width: 200px;
}

.landing-main-row {
	height: 90vh;
}

.landingpage-main {
	background: rgb(255, 255, 255);
	background: linear-gradient(
		159deg,
		rgba(220, 220, 220, 1) 0%,
		rgba(255, 255, 255, 0.5018382352941176) 49%,
		rgba(255, 255, 255, 0) 97%
	);
}
.landingpage-main img {
	width: 80%;
}

.landingpage-socials a {
	color: rgb(37, 37, 37);
	font-size: 1.8em;
	display: block;
	margin: auto;
}

.landing-book-now-btn-black {
	color: white;
	background-color: black;
	outline: none;
	font-size: 1.3em;
	font-weight: 700;
	padding: 12px 40px 10px 40px;
	border: none;
	display: block;
}
.landing-book-now-btn-white {
	color: rgb(0, 0, 0);
	background-color: rgb(255, 255, 255);
	outline: none;
	font-size: 1.3em;
	font-weight: 700;
	padding: 12px 40px 10px 40px;
	border: none;
	display: block;
}
.landing-page-marketplaces img {
	width: 40%;
}

.landing-section-2 h2 {
	color: #323232;
	font-size: 4em;
	font-weight: 700;
	line-height: 1.5em;
}

.landing-fade-heading {
	position: absolute;
	top: 10%;
	left: 13%;
	overflow: hidden;
	z-index: -1;
}
.landing-fade-heading h3 {
	font-size: 15em;
	font-weight: 900;
	text-transform: uppercase;
	background: #edf2ee;
	background: linear-gradient(
		to right,
		#f9faf9 0%,
		#82e0dd 100%
	);

	-webkit-text-fill-color: transparent;

	font-family: "Metropolis Bold";
}

.landing-x2-box {
	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(220, 220, 220, 1) 0%,
		rgba(220, 220, 220, 0.7) 49%,
		rgba(220, 220, 220, 0.3) 97%
	);
	border-radius: 20px;
}
.landing-section-3-images {
	gap: 8.5em;
}
.landing-section-3-images img {
	width: 30%;
	border-radius: 10px;
}

.landing-img-2 {
	width: 53% !important;
}
.landing-section-5 {
	background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/product/x3/X3_Product_Banner.jpg");
	height: 90vh;
	background-position: center;
	background-size: cover;
	position: relative;
}
.landing-section-5-child {
	top: 20%;
	position: absolute;
	left: 10%;
}

.landing-section-5-child h1 {
	font-size: 4.5em;
	line-height: 1.5em;
}

.landing-contact h1 {
	font-size: 4em;
	margin-top: 1em;
}
.landing-bike-cards button {
	width: 25%;
}

@media only screen and (max-width: 700px) {
	.landing-nav img {
		width: 130px;
	}
	.landing-nav h5 {
		font-size: 1em;
	}
	.landing-main-row {
		height: auto;
	}
	.landingpage-main img {
		width: 100%;
		margin-top: 4em;
		scale: 1.1;
	}
	.landingpage-main h1 {
		font-size: 1.5em;
	}
	.landingpage-main {
		background: rgb(255, 255, 255);
		background: linear-gradient(
			159deg,
			rgba(220, 220, 220, 1) 0%,
			rgba(255, 255, 255, 0.5018382352941176) 49%,
			rgba(255, 255, 255, 0) 97%
		);
	}

	.landingpage-socials a {
		color: rgb(37, 37, 37);
		font-size: 1.8em;
		display: block;
		margin: auto;
	}

	.landing-book-now-btn-black {
		color: white;
		background-color: black;
		outline: none;
		font-size: 1em;
		font-weight: 700;
		text-align: center;
		padding: 10px 30px 10px 30px;
		border: none;
		border-radius: 2px;
		display: block;
	}
	.landing-book-now-btn-white {
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
		outline: none;
		font-size: 1.3em;
		font-weight: 700;
		padding: 12px 40px 10px 40px;
		border: none;
		display: block;
	}

	.landing-page-marketplaces {
		margin-top: 1.5em;
	}
	.landing-page-marketplaces p {
		color: #868383;
	}
	.landing-page-marketplaces img {
		width: 70%;
	}

	.landing-section-2 h2 {
		color: #323232;
		font-size: 1.5em;
		font-weight: 700;
		line-height: 1.5em;
	}

	.landing-fade-heading {
		position: absolute;
		top: 10%;
		left: 13%;
		overflow: hidden;
		z-index: -1;
	}

	.landing-fade-heading h3 {
		font-size: 15em;
		font-weight: 900;
		text-transform: uppercase;
		background: #edf2ee;
		background: linear-gradient(
			to right,
			#f9faf9 0%,
			#82e0dd 100%
		);

		-webkit-text-fill-color: transparent;

		font-family: "Metropolis Bold";
	}

	.landing-section-3 h1 {
		font-size: 1.4em;
	}

	.landing-x2-box {
		background: rgb(255, 255, 255);
		background: linear-gradient(
			180deg,
			rgba(220, 220, 220, 1) 0%,
			rgba(220, 220, 220, 0.7) 49%,
			rgba(220, 220, 220, 0.3) 97%
		);
		border-radius: 20px;
	}

	.landing-section-3-images {
		gap: 0em;
	}
	.landing-section-3-images img {
		width: 100%;
	}
	.landing-img-2 {
		width: 100% !important;
	}

	.landing-section-5 {
		background-image: url("https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/product/x3/X3_Product_Banner.jpg");
		height: 30vh;
		background-position: center;
		background-size: cover;
		position: relative;
	}
	.landing-section-5-child {
		top: 20%;
		display: none;
		position: absolute;
		left: 10%;
	}

	.landing-section-5-child h1 {
		font-size: 4.5em;
		line-height: 1.5em;
	}

	.landing-contact h1 {
		font-size: 1.2em;
		margin-top: 1em;
	}
	.landing-contact h2 {
		font-size: 1em;
		margin-top: 1em;
	}
	.landing-bike-cards h2 {
		font-size: 1.1em;
	}
	.landing-bike-cards p {
		font-size: 0.8em;
	}
	.landing-bike-cards button {
		width: 50%;
	}
}
