.formula-fun-product-catalogue {
  button.splide__arrow svg {
    width: 60%;
    fill: black !important;
  }
  .splide__arrows button {
    border: none !important;
  }
  .splide__arrows--ttb .splide__arrow {
    left: 32% !important;
  }
}

.formula-fun-product-screen {
  .faqs-formula-fun .accordion-button:not(.collapsed) {
    background-color: #f3f3f3;
  }
  .faqs-formula-fun .accordion-button {
    display: flex;
    gap: 0.7em;
  }
  .accordion-button {
    background-color: #f3f3f3;
  }
  .stack-card {
    position: sticky;
    top: 0;
  }
  .review-productpage-content-ff {
    height: 300px;
  }
}

.product-name-ff {
  font-size: 1.6em;
}
.formula-fun-black-line {
  background-color: grey;
  height: 0.1px;
}

.buy-now-ff {
  background-color: #3d348b !important;
}

.buy-now-ff:hover {
  background-color: black !important;
}

.product-selling-price-ff {
  font-size: 1.7em;
}

.product-mrp-ff {
  font-size: 1.3em;
  text-decoration: line-through;
}

.product-save-percentage-ff {
  font-size: 0.9em;
  color: #00b412;
}

.ff-black-gradient-line {
  height: 1px;
  background: linear-gradient(to right, #ffffff 0%, #000000 49%, #ffffff 100%);
}

.product-tagline-ff {
  display: block;
  font-size: 0.9em;
}

.active-color-product-ff {
  border: 1px solid black;
  border-radius: 50%;
}

.product-usp-1-ff-div {
  gap: 1.2em;
  .image-div-usp {
    width: 25%;
  }
  .text-div-usp {
    width: 75%;
  }
}

.product-usp-2-ff-div {
  p {
    font-size: 0.85em;
  }
}

.h2-product-page-ff {
  text-align: center;
}

.specs-category-heading-ff {
  font-size: 1.5em;
}

.spec-text-ff {
  width: fit-content;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.5;
  font-size: 0.95em;
}

.specs-category-heading-ff {
  font-size: 1.1em;
}

@media screen and (max-width: 650px) {
  .h2-product-page-ff {
    font-size: 1.3em;
    text-align: center;
  }
  .product-name-ff {
    font-size: 1.3em;
  }
  .product-tagline-ff {
    font-size: 0.7em;
  }
  .product-selling-price-ff {
    font-size: 1.4em;
  }

  .product-mrp-ff {
    font-size: 1em;
  }

  .product-save-percentage-ff {
    font-size: 0.7em;
  }

  .product-price-tagline-ff {
    font-size: 0.7em;
  }

  .formula-fun-product-screen {
    .faqs-formula-fun .accordion-button {
      font-size: 0.6em;
    }
    .faqs-formula-fun .accordion-body {
      font-size: 0.9em;
    }
    .review-productpage-content-ff {
      height: 300px;
    }
  }

  .product-usp-1-ff-div {
    gap: 0.5em;
    flex-direction: column;
    p {
      font-size: 0.75em;
      /* line-height: 1px; */
    }
    .image-div-usp {
      width: 25%;
    }
    .text-div-usp {
      width: 50%;
    }
  }

  .product-usp-2-ff-div {
    p {
      font-size: 0.75em;
    }
  }
  .specs-category-heading-ff {
    font-size: 1.1em;
  }
  .specs-container-ff {
    /* gap: 0.5em; */
    flex-wrap: wrap;
    .spec-div-ff {
      flex: 1 1 calc(50% - 16px);
    }
    .spec-text-ff {
      font-size: 0.75em;
    }
  }
}
